import { Header, Pagination } from "../../../components";
import TableSkeleton from "../../../components/Table/TableSkeleton";
import UpdateButton from "./UpdateButton";
import RewardsTable from "./RewardsTable";
import useReward from "./useReward";
import { PaginationProps } from "../../../components/common/Pagination";
import { AfriexPermissions } from "../../../types";
import { AfriexActions } from "../../../types";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";

const RewardsList = () => {
  const { isLoading, isSearch, fetchList, total, list } = useReward();
  const totalFigure = total ? ` (${total.toLocaleString()})` : "";

  return (
    <main className="mt-10 mb-5 mx-4 md:mx-10">
      <Header title={"Manage Reward List" + totalFigure} />

      <PermissionsProvider
        permission={AfriexPermissions.REWARDS_PAGE}
        action={AfriexActions.CLICK_REWARDS_ADD_UPDATE}
      >
        <div className="w-full flex justify-end">
          <UpdateButton />
        </div>
      </PermissionsProvider>

      <section className="pt-5 md:pt-5">
        {isLoading ? <TableSkeleton /> : <RewardsTable items={list} />}
      </section>

      {list && list.length ? (
        <Pagination
          onPageChange={fetchList as PaginationProps["onPageChange"]}
          total={total}
          isSearch={isSearch}
        />
      ) : null}
    </main>
  );
};

export default RewardsList;
