import { Header, Pagination, TableSkeleton } from "..";
import { PaginationProps } from "../common/Pagination";
import LogsTable from "./LogsTable";
import useLogList from "./useLogList";

const AfriexLogsComponent = ({
  tags,
  title,
}: {
  tags: string[];
  title: string;
}) => {
  const { logsList, fetchAllLogs, isSearch, isPageReset, isLoading, pageInfo } =
    useLogList(tags);
  return (
    <main className="my-5 mx-4 md:mx-10">
      <Header title={title} />

      <section className="pt-5 md:pt-5">
        {isLoading ? <TableSkeleton /> : <LogsTable items={logsList} />}
      </section>

      {logsList && logsList.length ? (
        <Pagination
          onPageChange={fetchAllLogs as PaginationProps["onPageChange"]}
          total={pageInfo.total}
          isSearch={isSearch}
          isPageReset={isPageReset}
        />
      ) : null}
    </main>
  );
};

export default AfriexLogsComponent;
