import { Header, Pagination, TableSkeleton } from "../../../components";
import { PaginationProps } from "../../../components/common/Pagination";
import { doNothingAsync } from "../../../constants/formatters";
import { SupportedCurrencies } from "../../../types";
import ProcessorTable from "./ProcessorTable";
import UpdateProcessorModal from "./UpdateProcessorModal";
import useProcessorList from "./useProcessorList";

const ProcessorList = () => {
  const {
    isLoading,
    handleUpdateProcessor,
    pageInfo,
    isSearch,
    isPageReset,
    filteredCountryList,
    countryList,
    processorsLatest,
    processorsAll,
    selectedCurrency,
    shouldShowUpdateForm,
    toggleProcessorUpdateForm,
    showProcessorUpdateForm,
  } = useProcessorList();
  const totalFigure = pageInfo.total
    ? ` (${pageInfo.total.toLocaleString()})`
    : "";

  return (
    <main className="mt-10 mb-5 mx-4 md:mx-10">
      <Header title={"Processors By Corridor" + totalFigure} />

      <section className="pt-5 md:pt-5">
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <ProcessorTable
            items={filteredCountryList}
            countryList={countryList}
            processors={processorsLatest}
            showProcessorUpdateForm={showProcessorUpdateForm}
          />
        )}
      </section>

      {countryList && countryList.length ? (
        <Pagination
          onPageChange={doNothingAsync as PaginationProps["onPageChange"]}
          total={pageInfo.total}
          isSearch={isSearch}
          isPageReset={isPageReset}
          limit={100}
        />
      ) : null}

      <UpdateProcessorModal
        isOpen={shouldShowUpdateForm}
        onClose={toggleProcessorUpdateForm}
        handleUpdate={handleUpdateProcessor}
        processors={processorsAll}
        currency={selectedCurrency as SupportedCurrencies}
      />
    </main>
  );
};

export default ProcessorList;
