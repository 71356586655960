import { usePromotions } from "../../../pages/Promotions/usePromotions";
import { useCallback, useEffect, useState } from "react";
import useUserDetails from "./useUserDetails";
import UserTierTable from "./UserTierTable";
import UserCardListTable from "./UserCardListTable";
import { AxiosError } from "axios";
import { CustomInput } from "../../../components/common/Input";
import { Button } from "../../../components";
import { AfriexActions, AfriexTier } from "../../../types";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";
import { AfriexPermissions } from "../../../types";
import { toast } from "react-toastify";
import Spinner from "../../../assets/svg/Spinner";

const TierInformation = ({
  id,
  transactionCount,
  userId,
  refetchUser,
}: any) => {
  const {
    getPromotion,
    updatePromotionForUser,
    deletePromotionForUser,
    isLoading,
  } = usePromotions();
  const [promotion, setPromotion] = useState<AfriexTier | null>(null);
  const [tierIdInput, setTierIdInput] = useState<string>("");
  const { activeCards, updateCardBankRegulatedStatus } = useUserDetails(userId);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTierIdInput(event.target.value);
  };

  const handleUpdatePromotion = async () => {
    if (!tierIdInput.trim()) {
      toast.warn("Please provide a Tier ID.");
      return;
    }

    try {
      await updatePromotionForUser(userId, tierIdInput);
      await refetchUser();
      toast.success(`User Tier ${promotion ? "updated" : "added"}`);
      setTierIdInput("");
    } catch (error) {
      if ((error as AxiosError)?.response?.status === 404) {
        toast.error("Tier not found.");
        return;
      }
      toast.error(
        (error as AxiosError)?.message || "Error updating tier for user."
      );
    }
  };

  const handleDeletePromotion = async () => {
    try {
      await deletePromotionForUser(userId);
      await refetchUser();
      toast.success("User Tier deleted");
    } catch (error) {
      toast.error(
        (error as AxiosError)?.message || "Error deleting tier for user."
      );
    }
  };

  const fetchPromotion = useCallback(
    async (id: string) => {
      if (id) {
        getPromotion(id).then((promotion) => {
          setPromotion(promotion);
        });
      }
    },
    [id]
  );

  useEffect(() => {
    fetchPromotion(id);
  }, [fetchPromotion, id]);

  return (
    <div className="px-3 pt-3 mt-8">
      <h3 className="font-semibold text-gray-900 mb-2">
        {"Promos & Tiers".toLocaleUpperCase()}
      </h3>
      <hr className="mb-5" />

      <div className="flex flex-col gap-3">
        <h3 className="text-sm font-semibold text-gray-600">
          Tiers / Promotions
        </h3>

        <div className={"max-w"}>
          {promotion && id && (
            <UserTierTable
              tierInfo={promotion as any}
              transactionCount={transactionCount}
            />
          )}

          <PermissionsProvider
            permission={AfriexPermissions.USERS_PAGE}
            action={AfriexActions.CLICK_USER_TIERS_UPDATE_DELETE}
          >
            <div className="update-tier-form flex flex-col mt-5">
              <h1 className="text-sm font-semibold mb-2 text-gray-600">
                {id ? "Modify / Delete " : "Add "} Tier
              </h1>

              <div className="flex gap-5">
                <CustomInput
                  type="text"
                  value={tierIdInput}
                  onChange={handleInputChange}
                  placeholder="Tier ID"
                  className="w-60"
                />

                <div className="flex gap-3">
                  <Button
                    variant="solid"
                    colorScheme="cyan"
                    onClick={handleUpdatePromotion}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Spinner />
                    ) : (
                      (promotion ? "Update" : "Add User to") + " Tier"
                    )}
                  </Button>

                  {promotion && id && (
                    <Button
                      variant="solid"
                      colorScheme="red"
                      onClick={handleDeletePromotion}
                      disabled={isLoading}
                    >
                      {isLoading ? <Spinner /> : "Delete Tier"}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </PermissionsProvider>
        </div>

        <div className="mt-3">
          <h2 className="text-sm font-semibold text-gray-600 mb-2">
            Active Cards
          </h2>
          <UserCardListTable
            activeCardList={activeCards}
            userId={userId}
            updateCardBankRegulatedStatus={updateCardBankRegulatedStatus}
          />
        </div>
      </div>
    </div>
  );
};

export default TierInformation;
