import { AdminTransactionTypes, TransactionTypes } from "../types/Transaction";
import * as formatDate from "./date";
import { getNigeriaBankName } from "./bankUtil";
import { SupportedAssets } from "../types/AssetTypes";
import { AfriexTransaction, RatesMap } from "../types";
import {
  capitalizeWords,
  formatValues,
  formatTxChannel,
  compareAndFilterFields,
} from "./dashboard";

export const showCurrencyRate = (transaction: AfriexTransaction): string => {
  const ratesObject = transaction?.rates as RatesMap;
  const sourceAsset = transaction.sourceAsset as SupportedAssets;
  const destinationAsset = transaction.destinationAsset as SupportedAssets;
  const nestedRate =
    ratesObject?.[sourceAsset as SupportedAssets]?.[
      destinationAsset as SupportedAssets
    ] ?? 0;
  const flattenedRate = ratesObject?.[destinationAsset as SupportedAssets] ?? 0;
  const exchangeRate =
    flattenedRate?.constructor === Object ? nestedRate : flattenedRate;
  return `1 ${transaction.sourceAsset} = ${formatValues(Number(exchangeRate), "rate", 6)} ${
    transaction?.destinationAsset as string
  }`;
};

interface DisplayField {
  [key: string]: string | number | any;
}

export function getFieldsToDisplay(
  transaction: AfriexTransaction
): DisplayField[] {
  const typeCheck =
    Object.values(AdminTransactionTypes).find(
      (key) =>
        key ===
        AdminTransactionTypes[
          transaction.type as unknown as keyof typeof AdminTransactionTypes
        ]
    ) || transaction.type;

  const feeMeta = transaction?.meta?.fee;
  const deviceInfoFields = [
    { "Customer Device Id": transaction?.deviceInfo?.deviceId },
    { "Customer Device Type": transaction?.deviceInfo?.deviceType },
    {
      "Customer Device Name": transaction?.deviceInfo?.deviceName?.replace(
        /[{}]/g,
        ""
      ),
    },
  ];

  const feeFields = [
    {
      Fee: transaction.fee
        ? formatValues(transaction.fee, "money", 4) +
          (feeMeta?.baseValue ? ` ${transaction.sourceAsset}` : "%")
        : null,
    },
    { "Fee Rule": JSON.stringify(feeMeta) },
  ];
  const promoTierFields = [
    {
      "Promo Tier": transaction?.meta?.promoApplied?.code
        ? capitalizeWords(
            transaction?.meta?.promoApplied?.code?.replace(/_/g, " ") || ""
          )
        : null,
    },
    {
      "Tier Discount": transaction?.meta?.promoApplied?.discount
        ? formatValues(
            (transaction?.meta?.promoApplied?.discount ?? 0) * 100,
            "rate",
            6
          ) + "%"
        : null,
    },
  ];

  const bankAccountName = {
    "Bank Account Name": transaction.destinationAccountDetails?.accountName,
  };
  const recipientName = {
    "Recipient Name": transaction.destinationAccountDetails?.accountName,
  };
  const accountNameFields = compareAndFilterFields(
    bankAccountName,
    recipientName
  );

  const txId = { "Transaction ID": transaction.id };
  const ledgerTxId = {
    "Ledger Transaction IDs": transaction.ledgerTransactionIds,
  };
  const txIdFields = compareAndFilterFields(txId, ledgerTxId);

  const processorReference = {
    "Processor Reference": transaction.processorTransactionId,
  };
  const internalReference = {
    "Internal Reference": transaction.internalTransactionId,
  };
  const referenceFields = compareAndFilterFields(
    processorReference,
    internalReference
  );

  switch (typeCheck) {
    case TransactionTypes.WITHDRAW:
      return [
        {
          Type: capitalizeWords(transaction.type?.replace(/_/g, " ")),
        },
        {
          Description: transaction?.processorResponseMeta?.statusDescription,
        },
        ...txIdFields,
        { "Deposit Transaction ID": transaction?.depositTransactionReference },
        { "Transaction IP Address": transaction.ip },
        {
          "Date Initiated": formatDate.dateTimeInTimeZone(
            transaction.createdAt?.toString()
          ),
        },
        {
          "Date Updated": formatDate.dateTimeInTimeZone(
            transaction.updatedAt?.toString()
          ),
        },
        { Processor: transaction.processor },
        {
          "Initiated Amount":
            formatValues(Number(transaction.sourceAmount), "money") +
            " " +
            transaction.sourceAsset,
        },
        {
          "Fulfilled Amount":
            formatValues(Number(transaction.destinationAmount), "money") +
            " " +
            transaction.destinationAsset,
        },
        { "Currency Rate": showCurrencyRate(transaction) },
        { Channel: formatTxChannel(transaction) },
        ...feeFields,
        ...promoTierFields,
        { "Initiated By": transaction?.adminUser?.name },
        {
          Reason: capitalizeWords(
            (transaction.reason as string)?.replace(/_/g, " ")
          ),
        },
        ...referenceFields,
        {
          "Session ID": transaction?.processorResponseMeta?.sessionId,
        },
        {
          "Bank name":
            transaction.destinationAccountDetails?.bankName ||
            getNigeriaBankName(transaction.destinationAccountDetails?.bankCode),
        },
        {
          "Bank account #":
            transaction.destinationAccountDetails?.accountNumber,
        },
        ...accountNameFields,
        {
          "Recipient Phone":
            transaction.destinationAccountDetails?.accountPhone,
        },
        { "Tier Id": transaction?.tierId ?? "None" },
        { "Customer ID": transaction.sourceUserId },
        { Status: transaction.status },
        ...deviceInfoFields,
      ];
    case TransactionTypes.DEPOSIT:
      return [
        {
          Type: capitalizeWords(transaction.type?.replace(/_/g, " ")),
        },
        {
          Description: transaction?.processorResponseMeta?.statusDescription,
        },
        ...txIdFields,
        { "Transaction IP Address": transaction.ip },
        {
          "Date Initiated": formatDate.dateTimeInTimeZone(
            transaction.createdAt?.toString()
          ),
        },
        {
          "Date Updated": formatDate.dateTimeInTimeZone(
            transaction.updatedAt?.toString()
          ),
        },
        {
          "Initiated Amount":
            formatValues(Number(transaction.sourceAmount), "money") +
            " " +
            transaction.sourceAsset,
        },
        {
          "Fulfilled Amount":
            formatValues(Number(transaction.destinationAmount), "money") +
            " " +
            transaction.destinationAsset,
        },
        { "Currency Rate": showCurrencyRate(transaction) },
        ...feeFields,
        ...promoTierFields,
        { "Customer ID": transaction.sourceUserId },
        { Channel: formatTxChannel(transaction) },
        { Processor: transaction.processor },

        { "Initiated By": transaction?.adminUser?.name },
        {
          Reason: capitalizeWords(
            (transaction?.reason as string)?.toString().replace(/_/g, " ")
          ),
        },
        {
          "Stripe Payment Intent Id":
            transaction.processorResponseMeta?.paymentIntentId,
        },
        ...referenceFields,
        { Status: transaction.status },
        ...deviceInfoFields,
      ];
    case TransactionTypes.REVERSAL:
      return [
        {
          Type: capitalizeWords(transaction.type?.replace(/_/g, " ")),
        },
        {
          Description: transaction?.processorResponseMeta?.statusDescription,
        },
        ...txIdFields,
        { "Transaction IP Address": transaction.ip },
        { Channel: formatTxChannel(transaction) },
        {
          "Date Initiated": formatDate.dateTimeInTimeZone(
            transaction.createdAt?.toString()
          ),
        },
        {
          "Date Updated": formatDate.dateTimeInTimeZone(
            transaction.updatedAt?.toString()
          ),
        },
        {
          "Initiated Amount":
            formatValues(Number(transaction.sourceAmount), "money") +
            " " +
            transaction.sourceAsset,
        },
        {
          "Fulfilled Amount":
            formatValues(Number(transaction.destinationAmount), "money") +
            " " +
            transaction.destinationAsset,
        },
        { "Currency Rate": showCurrencyRate(transaction) },
        ...promoTierFields,
        ...referenceFields,
        { "Session ID": transaction?.processorResponseMeta?.sessionId },
        { "Bank name": transaction.destinationAccountDetails?.bankName },
        {
          "Bank account #":
            transaction.destinationAccountDetails?.accountNumber,
        },
        {
          "Bank account name":
            transaction.destinationAccountDetails?.accountName,
        },
        {
          "Recipient Name": transaction.destinationAccountDetails?.accountName,
        },
        {
          "Recipient Phone":
            transaction.destinationAccountDetails?.accountPhone,
        },
        { "Customer ID": transaction.destinationUserId },
        { Status: transaction.status },
      ];
    case TransactionTypes.REFERRAL:
      return [
        {
          Type: capitalizeWords(transaction.type?.replace(/_/g, " ")),
        },
        {
          Description: transaction?.processorResponseMeta?.statusDescription,
        },
        ...txIdFields,
        { "Transaction IP Address": transaction.ip },
        {
          "Date Initiated": formatDate.dateTimeInTimeZone(
            transaction.createdAt?.toString()
          ),
        },
        {
          "Date Updated": formatDate.dateTimeInTimeZone(
            transaction.updatedAt?.toString()
          ),
        },
        {
          "Initiated Amount":
            formatValues(Number(transaction.sourceAmount), "money") +
            " " +
            transaction.sourceAsset,
        },
        ...promoTierFields,
        { "Currency Rate": showCurrencyRate(transaction) },
        { Status: transaction.status },
        ...deviceInfoFields,
      ];
    case TransactionTypes.TRANSFER:
      return [
        { Type: `User to User` },
        { Description: transaction.processorResponseMeta?.statusDescription },
        ...txIdFields,
        { "Transaction IP Address": transaction.ip },
        { "Sender Username": transaction.sourceAccountDetails?.name },
        { "Receiver Username": transaction.destinationAccountDetails?.name },
        {
          "Date Initiated": formatDate.dateTimeInTimeZone(
            transaction.createdAt?.toString()
          ),
        },
        {
          "Date Updated": formatDate.dateTimeInTimeZone(
            transaction.updatedAt?.toString()
          ),
        },
        { Sender: transaction.sourceUserId },
        { Receiver: transaction.destinationUserId },
        {
          "Initiated Amount":
            formatValues(Number(transaction.sourceAmount), "money") +
            " " +
            transaction.sourceAsset,
        },
        {
          "Recipient Amount":
            formatValues(Number(transaction.destinationAmount), "money") +
            " " +
            transaction.destinationAsset,
        },
        { "Currency Rate": showCurrencyRate(transaction) },
        ...feeFields,
        ...promoTierFields,
        { Channel: formatTxChannel(transaction) },
        { Status: "Successful" },
        ...deviceInfoFields,
      ];
    case TransactionTypes.SWAP:
      return [
        {
          Type: capitalizeWords(transaction.type?.replace(/_/g, " ")),
        },
        {
          Description: transaction?.processorResponseMeta?.statusDescription,
        },
        ...txIdFields,
        { "Transaction IP Address": transaction.ip },
        {
          "Initiated Amount":
            formatValues(Number(transaction.sourceAmount), "money") +
            " " +
            transaction.sourceAsset,
        },
        {
          "Fulfilled Amount":
            formatValues(Number(transaction.destinationAmount), "money") +
            " " +
            transaction.destinationAsset,
        },
        { "Currency Rate": showCurrencyRate(transaction) },
        ...feeFields,
        ...promoTierFields,
        {
          "Date Initiated": formatDate.dateTimeInTimeZone(
            transaction.createdAt?.toString()
          ),
        },
        {
          "Date Updated": formatDate.dateTimeInTimeZone(
            transaction.updatedAt?.toString()
          ),
        },
        { "Customer Id": transaction.sourceUserId },
        { "Initiated By": transaction.initiatedBy },
        { Status: transaction.status },
        ...deviceInfoFields,
      ];
    default:
      return [
        {
          Type: capitalizeWords(transaction.type?.replace(/_/g, " ")),
        },
        {
          Description: transaction?.processorResponseMeta?.statusDescription,
        },
        ...txIdFields,
        { "Transaction IP Address": transaction.ip },
        { Description: transaction.type },
        {
          "Date Initiated": formatDate.dateTimeInTimeZone(
            transaction.createdAt?.toString()
          ),
        },
        {
          "Date Updated": formatDate.dateTimeInTimeZone(
            transaction.updatedAt?.toString()
          ),
        },
        { Processor: transaction.processor },

        {
          "Initiated Amount":
            formatValues(Number(transaction.sourceAmount), "money") +
            " " +
            transaction.sourceAsset,
        },
        {
          "Initiated Amount":
            formatValues(Number(transaction.destinationAmount), "money") +
            " " +
            transaction.destinationAsset,
        },

        {
          "Fulfilled Amount": formatValues(
            Number(transaction.sourceAmount),
            "money"
          ),
        },
        { "Currency Rate": showCurrencyRate(transaction) },
        ...feeFields,
        ...promoTierFields,
        { Channel: formatTxChannel(transaction) },
        { "Initiated By": transaction?.adminUser?.name },
        {
          Reason: capitalizeWords(
            (transaction?.reason as string)?.toString().replace(/_/g, " ")
          ),
        },
        ...referenceFields,
        { "Customer ID": transaction.sourceUserId },
        { Status: transaction.status },
        ...deviceInfoFields,
      ];
  }
}
