import { formatTime } from "../utils";

const ratesHeaderHue = "bg-blue-50 p-8 shadow-sm sticky";
const SubCurrencyTable = ({
  data: groupedByCurrency,
  currency,
}: {
  data: any;
  currency: string;
}) => {
  return (
    <table
      className={`min-w-full w-full divide-y divide-gray-200 border shadow-sm rounded-md`}
    >
      <thead className={ratesHeaderHue}>
        <tr>
          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Corridor Pair
          </th>
          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Processor
          </th>
          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Number of Transactions
          </th>
          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Success Rate (%)
          </th>
          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Average Time
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {Object.values(groupedByCurrency[currency]).map((item, index) => {
          return <SubCurrencyTRow item={item} key={index} index={index} />;
        })}
      </tbody>
    </table>
  );
};

const SubCurrencyTRow = ({ item, index }: { item: any; index: number }) => {
  const textClassName = "text-sm text-gray-800";
  const zebraRow = index % 2 === 0 ? "" : "bg-gray-50";
  const {
    corridor_pair,
    processor,
    total_no_txn_per_p_cp_pi,
    success_count,
    total_duration,
  } = item as any;
  const successRate = (success_count / total_no_txn_per_p_cp_pi) * 100;
  const avgTime = success_count ? total_duration / success_count : 0;

  return (
    <tr
      className={`transition-background transition-colors transition-opacity ease-in-out duration-500 hover:bg-gray-100 ${zebraRow}`}
    >
      <td
        className={`py-4 px-2 whitespace-nowrap text-left ${textClassName} font-medium`}
      >
        {corridor_pair}
      </td>
      <td
        className={`py-4 px-2 whitespace-nowrap text-left ${textClassName} font-medium`}
      >
        {processor}
      </td>
      <td
        className={`py-4 px-2 whitespace-nowrap text-left ${textClassName} font-medium`}
      >
        {total_no_txn_per_p_cp_pi}
      </td>
      <td
        className={`py-4 px-2 whitespace-nowrap text-left ${textClassName} font-medium`}
      >
        {successRate}
      </td>
      <td
        className={`py-4 px-2 whitespace-nowrap text-left ${textClassName} font-medium`}
      >
        {formatTime(avgTime)}
      </td>
    </tr>
  );
};

export default SubCurrencyTable;
