import { useCallback, useEffect, useState } from "react";
import useFilters from "../../hooks/useFilters";
import { AfriexLogParams, AfriexTelemetry } from "../../types";
import { logService } from "../../services/logService";

const useLogList = (tags: string[] = []) => {
  const [logsList, setLogsList] = useState<AfriexTelemetry[]>([]);
  const [logsTotal, setLogsTotal] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSearch] = useState<boolean>(false);
  const [showPagination, setShowPagination] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [isPageReset, setIsPageReset] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const [currentFilters, setCurrentFilters] = useState<AfriexLogParams>({
    tags,
  });
  const [filtersToApply, setFiltersToApply] = useState<AfriexLogParams>({
    tags,
  });

  const [filterCount, setFilterCount] = useState(0);
  const { applyFilters } = useFilters(setCurrentFilters);

  const fetchAllLogs = useCallback(
    async (params: AfriexLogParams) => {
      try {
        setError(null);
        setIsLoading(true);
        const { data, total } = await logService.getLogList({
          ...params,
          ...currentFilters,
        });
        setLogsList(data);
        setLogsTotal(total ?? 0);
        if (total && total > 10) {
          setShowPagination(true);
        }
      } catch (e) {
        setError(e as any);
      } finally {
        setIsLoading(false);
      }
    },
    [currentFilters]
  );

  useEffect(() => {
    fetchAllLogs({ tags });
  }, [fetchAllLogs, tags]);

  const handleApplyFilters = useCallback(() => {
    applyFilters(filtersToApply);
    setIsPageReset((prev) => !prev);
  }, [applyFilters, filtersToApply]);

  const selectCurrentFilters = useCallback(
    (name: string, value: string | Date | undefined) => {
      setCurrentFilters((prev) => {
        const updatedFilters = {
          ...prev,
          [name]: value,
        };
        return updatedFilters;
      });
      setIsPageReset((prev) => !prev);
    },
    []
  );

  const selectAppliedFilters = useCallback(
    (name: string, value: string | Date | undefined) => {
      setFiltersToApply((prev) => {
        const updatedFilters = {
          ...prev,
          [name]: value,
        };
        // Only count non-undefined values
        const filteredCount = Object.entries(updatedFilters).filter(
          ([_, val]) => val !== undefined
        ).length;
        setFilterCount(filteredCount);
        return updatedFilters;
      });
    },
    []
  );

  const cleanFilters = () => {
    setCurrentFilters({ tags });
    setFiltersToApply({ tags });
    setFilterCount(0);
    setIsPageReset((prev) => !prev);
  };
  return {
    error,
    fetchAllLogs,
    isLoading,
    isSearch,
    setPage,
    pageInfo: {
      page,
      total: logsTotal,
    },
    showPagination,
    logsList,
    filterCount,
    isPageReset,
    currentFilters,
    filtersToApply,
    clearFilters: cleanFilters,
    selectAppliedFilters,
    handleApplyFilters,
    selectCurrentFilters,
  };
};

export default useLogList;
