import {
  Button,
  SpinningLoader,
  CheckBox,
  CurrencyPicker,
  CountryPicker,
  CustomInput,
  CustomSelect,
} from "../../../components";
import {
  AfriexRateInfo,
  AfriexRateTierCode,
  AfriexTier,
  ArbitrageCheckInfo,
  SupportedCountryCodes,
  SupportedCurrencies,
  TransactionChannels,
} from "../../../types";
import useTierUpdate from "./useTierUpdate";
import { TierAction, TierActionType } from "./types";
import { isEmpty } from "lodash";
import ArbitrageDisplay from "../Upload/ArbitrageDisplay";
import { convertRatesListToMap } from "../../../utils/rates/convertRatesListToMap";
import { applyDiscountToRates } from "../../../utils/rates/applyDiscountToRates";
import { toTitleCase } from "../../../utils/services/toTitleCase";
import RateWidget from "./RateWidget";
import { UserModalProps } from "../../../components/DeactivateAccount/types";
import CustomModal from "../../../components/CustomModal";
import React from "react";
import { XIcon } from "@heroicons/react/outline";
import { capitalizeWords, formatValues } from "../../../utils/dashboard";

interface TierFormModalProps
  extends Pick<UserModalProps, "isOpen" | "onClose"> {
  initialData?: AfriexTier;
  rateInfo?: AfriexRateInfo;
}

const TierFormModal = ({
  isOpen,
  onClose,
  initialData,
  rateInfo,
}: TierFormModalProps) => {
  const {
    arbitrageResult,
    isArbitrageDetected,
    isArbitrageChecked,
    handleToleranceChange,
    checkIfRateTierHasArbitrage,
    dispatch,
    isLoading,
    handleSubmit,
    shouldShowArbitrageTable,
    setShouldShowArbitrageTable,
    tier,
    tolerance,
  } = useTierUpdate(onClose, initialData, rateInfo);

  const {
    discount,
    isActive,
    shouldSkipFee,
    sourceCurrencies,
    currencies,
    allowedSourceChannels,
    allowedDestChannels,
    allowedCountries,
    name,
    transactionValueThreshold,
    transactionCountThreshold,
    type,
  } = tier;

  const updateAction = {
    type: TierActionType.UPDATE_TIER,
    payload: {},
  };
  const updateLabel = isEmpty(initialData)
    ? "Create New Promo Rate +"
    : "Update Promo Tier";
  const submitLabel = isEmpty(initialData) ? "Submit" : "Update";
  const isDisabled = isEmpty(tier);

  const typeSelectionOptions = [
    { value: "", label: "--Select Type--" },
    { value: "promotion", label: "Promotion" },
    { value: "tier", label: "Tier" },
    { value: "paymentMethod", label: "Payment Method" },
  ];
  const codeSelectionOptions = [
    { value: "", key: "--Select Code--" },
    ...Object.values(AfriexRateTierCode).map((code) => ({
      value: code,
      key: toTitleCase(code?.replace("_", " ")),
    })),
  ];
  const disallowedChannels = [
    TransactionChannels.INTERNAL,
    TransactionChannels.WIDGET,
    TransactionChannels.CRYPTO,
  ];
  const channelOptions = Object.values(TransactionChannels)
    .filter((x) => !disallowedChannels.includes(x))
    ?.map((channel) => ({
      value: channel,
      key: toTitleCase(channel?.replace("_", " ")),
    }));
  const sourceSelectionOptions = [
    { value: "", key: "--Select Channel--" },
    ...channelOptions,
  ];
  const destSelectionOptions = [
    { value: "", key: "--Select Channel--" },
    ...channelOptions,
  ];

  const defaultType =
    typeSelectionOptions.find((option) => option.value === type) ??
    typeSelectionOptions[0];
  const discountedRates = applyDiscountToRates(
    rateInfo?.appRates ?? [],
    tier.discount ?? 0,
    tier.currencies
  );
  const discountedRatesMap = convertRatesListToMap(discountedRates);

  const shouldShowArbitrageInfo =
    isArbitrageDetected && isArbitrageChecked && shouldShowArbitrageTable;
  const arbitrageButtonLabel = shouldShowArbitrageInfo
    ? "Hide Arbitrage Info"
    : "Check Arbitrage";
  const arbitrageButtonHandler = shouldShowArbitrageInfo
    ? () => setShouldShowArbitrageTable(false)
    : () => checkIfRateTierHasArbitrage();

  const discountInPercentage = formatValues((discount ?? 0) * 100, "rate", 6);
  return (
    <React.Fragment>
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        width="w-96"
        height="h-4/5"
        closeOnOutsideClick
        title={updateLabel}
      >
        <div className="px-4 pt-3">
          {arbitrageResult && arbitrageResult?.currencies?.length && (
            <ArbitrageDisplay
              isOpen={
                arbitrageResult && arbitrageResult?.currencies?.length > 0
              }
              arbitrageResult={arbitrageResult}
              rates={discountedRatesMap}
              tolerance={tolerance}
            />
          )}

          {isLoading ? (
            <div className="flex w-full h-full justify-center items-center my-5">
              <SpinningLoader />
            </div>
          ) : (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(tier as AfriexTier) as any;
              }}
              className="flex flex-col space-y-5"
            >
              <RateWidget
                rates={rateInfo?.appRates ?? []}
                discount={discount ?? 0}
              />

              <CustomInput
                label="Name"
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    ...updateAction,
                    payload: {
                      name: e.target?.value ?? name,
                    },
                  })
                }
                className="w-full"
              />

              <CustomInput
                label={
                  <span>
                    Discount value (
                    <span
                      className={`font-semibold text-sm ${Number(discountInPercentage) >= 100 ? "text-red-500" : "text-gray-700"}`}
                    >
                      {discountInPercentage}%
                    </span>
                    )
                  </span>
                }
                min="-1"
                max="100"
                step="0.0001"
                type="number"
                value={discount}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    ...updateAction,
                    payload: {
                      discount: e.target?.value
                        ? Number(e.target?.value)
                        : discount,
                    },
                  })
                }
                className="w-full"
              />

              <CustomInput
                label="Max Allowed Transactions Count"
                min="0"
                type="number"
                value={transactionCountThreshold}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    ...updateAction,
                    payload: {
                      transactionCountThreshold: e.target?.value
                        ? Number(e.target?.value)
                        : transactionCountThreshold,
                    },
                  })
                }
                className="w-full"
              />

              <CustomInput
                label="Max Allowed Transactions Volume (Optional)"
                min="0"
                type="number"
                value={transactionValueThreshold}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    ...updateAction,
                    payload: {
                      transactionValueThreshold: e.target?.value
                        ? Number(e.target?.value)
                        : transactionValueThreshold,
                    },
                  })
                }
                className="w-full"
              />

              <CustomSelect
                label="Code"
                value={tier?.code || defaultType.value}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  dispatch({
                    ...updateAction,
                    payload: {
                      code:
                        (e.target?.value as AfriexRateTierCode) ??
                        (defaultType.value as AfriexRateTierCode),
                    },
                  })
                }
                name="role"
                options={codeSelectionOptions}
                placeholder="Filter by Role"
              />

              <div className="flex flex-col gap-2">
                <ul className="flex flex-row flex-wrap gap-2">
                  {allowedSourceChannels?.map((channel, index) => {
                    return channel?.length > 0 ? (
                      <li
                        className="flex items-center gap-2 p-1 px-2 rounded bg-indigo-300 text-sm"
                        key={index}
                      >
                        {capitalizeWords(channel?.replace(/_/g, " "))}
                        <XIcon
                          className="w-4 h-4 text-red-500 cursor-pointer"
                          onClick={() => {
                            dispatch({
                              type: TierActionType.REMOVE_SUPPORTED_SOURCE_PAYMENT_METHOD,
                              payload: channel,
                            });
                            return false;
                          }}
                        />
                      </li>
                    ) : (
                      ""
                    );
                  })}
                </ul>

                <CustomSelect
                  label="Allowed Source Payment Channels (Optional)"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    dispatch({
                      type: allowedSourceChannels?.includes(
                        e.target?.value as TransactionChannels
                      )
                        ? TierActionType.REMOVE_SUPPORTED_SOURCE_PAYMENT_METHOD
                        : TierActionType.ADD_SUPPORTED_SOURCE_PAYMENT_METHOD,
                      payload:
                        (e.target?.value as TierAction["payload"]) ??
                        (defaultType.value as TierAction["payload"]),
                    })
                  }
                  options={sourceSelectionOptions}
                  placeholder="--Select Channel--"
                />
              </div>

              <div className="flex flex-col gap-2">
                <ul className="flex flex-row flex-wrap gap-2">
                  {allowedDestChannels?.map((channel, index) => {
                    return channel?.length > 0 ? (
                      <li
                        className="flex items-center gap-2 p-1 px-2 rounded bg-indigo-300 text-sm"
                        key={index}
                      >
                        {capitalizeWords(channel?.replace(/_/g, " "))}
                        <XIcon
                          className="w-4 h-4 text-red-500 cursor-pointer"
                          onClick={() => {
                            dispatch({
                              type: TierActionType.REMOVE_SUPPORTED_TARGET_PAYMENT_METHOD,
                              payload: channel,
                            });
                            return false;
                          }}
                        />
                      </li>
                    ) : (
                      ""
                    );
                  })}
                </ul>

                <CustomSelect
                  label="Allowed Destination Payment Channels (Optional)"
                  defaultValue={allowedDestChannels}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    dispatch({
                      type: allowedDestChannels?.includes(
                        e.target?.value as TransactionChannels
                      )
                        ? TierActionType.REMOVE_SUPPORTED_TARGET_PAYMENT_METHOD
                        : TierActionType.ADD_SUPPORTED_TARGET_PAYMENT_METHOD,
                      payload:
                        (e.target?.value as TierAction["payload"]) ??
                        (defaultType.value as TierAction["payload"]),
                    })
                  }
                  options={destSelectionOptions}
                  placeholder="--Select Channel--"
                />
              </div>
              <div>
                <ul className="flex flex-row flex-wrap gap-2">
                  {sourceCurrencies?.map((currencyCode, index) => {
                    return currencyCode?.length > 0 ? (
                      <li
                        className="flex items-center gap-2 p-1 px-2 rounded bg-indigo-300 text-sm"
                        key={index}
                      >
                        {currencyCode}
                        <XIcon
                          className="w-4 h-4 text-red-500 cursor-pointer"
                          onClick={() => {
                            dispatch({
                              type: TierActionType.REMOVE_SUPPORTED_SOURCE_CURRENCY,
                              payload: currencyCode,
                            });
                            return false;
                          }}
                        />
                      </li>
                    ) : (
                      ""
                    );
                  })}
                </ul>

                <CurrencyPicker
                  label="Add Source Currency"
                  value={
                    sourceCurrencies?.[sourceCurrencies?.length - 1] ??
                    ("" as SupportedCurrencies)
                  }
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    dispatch({
                      type: TierActionType.ADD_SUPPORTED_SOURCE_CURRENCY,
                      payload: e.target.value as SupportedCurrencies,
                    })
                  }
                  placeholder="--Select Source Currency--"
                />
              </div>
              <div>
                <ul className="flex flex-row flex-wrap gap-2">
                  {currencies?.map((currencyCode, index) => {
                    return currencyCode?.length > 0 ? (
                      <li
                        className="flex items-center gap-2 p-1 px-2 rounded bg-indigo-300 text-sm"
                        key={index}
                      >
                        {currencyCode}
                        <XIcon
                          className="w-4 h-4 text-red-500 cursor-pointer"
                          onClick={() => {
                            dispatch({
                              type: TierActionType.REMOVE_SUPPORTED_CURRENCY,
                              payload: currencyCode,
                            });
                            return false;
                          }}
                        />
                      </li>
                    ) : (
                      ""
                    );
                  })}
                </ul>

                <CurrencyPicker
                  label="Add Destination Currency"
                  value={
                    currencies?.[currencies?.length - 1] ??
                    ("" as SupportedCurrencies)
                  }
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    dispatch({
                      type: TierActionType.ADD_SUPPORTED_CURRENCY,
                      payload: e.target.value as SupportedCurrencies,
                    })
                  }
                  placeholder="--Select Destination Currency--"
                />
              </div>

              <div>
                <ul className="flex flex-row flex-wrap gap-2">
                  {allowedCountries?.map(
                    (country: SupportedCountryCodes, index: number) => {
                      return country?.length > 0 ? (
                        <li
                          className="flex items-center gap-2 p-1 px-2 rounded bg-indigo-300 text-sm"
                          key={index}
                        >
                          {country}
                          <XIcon
                            className="w-4 h-4 text-red-500 cursor-pointer"
                            onClick={() => {
                              dispatch({
                                type: TierActionType.REMOVE_SUPPORTED_COUNTRY,
                                payload: country,
                              });
                              return false;
                            }}
                          />
                        </li>
                      ) : (
                        ""
                      );
                    }
                  )}
                </ul>

                <CountryPicker
                  label="Allowed User Countries (Optional)"
                  value={allowedCountries?.[0]}
                  onChange={(evt: React.ChangeEvent<HTMLSelectElement>) =>
                    dispatch({
                      type: TierActionType.ADD_SUPPORTED_COUNTRY,
                      payload: evt.target.value as SupportedCountryCodes,
                    })
                  }
                  placeholder="--Select Country--"
                />
              </div>

              <CheckBox
                label="Skip Fee"
                type="checkbox"
                checked={Boolean(shouldSkipFee)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    ...updateAction,
                    payload: {
                      shouldSkipFee: Boolean(
                        e.target?.checked ?? shouldSkipFee
                      ),
                    },
                  })
                }
              />
              <CheckBox
                label="Active"
                type="checkbox"
                checked={Boolean(isActive)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    ...updateAction,
                    payload: {
                      isActive: Boolean(e.target?.checked ?? isActive),
                    },
                  })
                }
              />

              {shouldShowArbitrageInfo && (
                <ArbitrageDisplay
                  isOpen={shouldShowArbitrageInfo}
                  onClose={arbitrageButtonHandler}
                  arbitrageResult={arbitrageResult as ArbitrageCheckInfo}
                  rates={discountedRatesMap}
                  tolerance={tolerance}
                  onToleranceChange={handleToleranceChange}
                />
              )}

              <div className="flex w-full justify-end space-x-3 py-5">
                {!shouldShowArbitrageInfo && (
                  <Button
                    type="button"
                    onClick={arbitrageButtonHandler}
                    variant="outline"
                    colorScheme="cyan"
                  >
                    {arbitrageButtonLabel}
                  </Button>
                )}

                <Button
                  disabled={isDisabled}
                  type="submit"
                  variant="solid"
                  colorScheme="cyan"
                >
                  {submitLabel}
                </Button>
              </div>
            </form>
          )}
        </div>
      </CustomModal>
    </React.Fragment>
  );
};

export default TierFormModal;
