import { AfriexActions } from "../../../types/AfriexRoles";
import { AfriexPermissions } from "../../../types/AfriexRoles";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";
import { Button, CustomSelect, DatePicker, Link } from "../../../components";
import { getCurrencyOptions } from "../../../constants/formatters";
import { mapCountryListToCurrencies } from "../../../utils/countries";
import useCountryList from "../../../hooks/useCountryList";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import OTCActionButton from "./OTCActionButton";
import { OTCFiltersProps } from "./types";
import DownloadOTCTradeCSVButton from "./DownloadOTCTradeCSVButton";
import { useState } from "react";
import { Filter } from "../../../components/Filter";

function OTCTradesFilters({
  filterCount,
  filtersToApply,
  currentFilters,
  clearFilters,
  selectAppliedFilters,
  handleApplyFilters,
  selectCurrentFilters,
}: OTCFiltersProps) {
  const { countryList } = useCountryList();
  const currencyOptions = mapCountryListToCurrencies(countryList ?? []);
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const fromDate = filtersToApply.fromDate
    ? new Date(filtersToApply.fromDate)
    : undefined;

  const toDate = filtersToApply.toDate
    ? new Date(filtersToApply.toDate)
    : undefined;

  const handleShowFilterChange = (): void => {
    setShowFilters(!showFilters);
  };

  const handleApplyFilter = (): void => {
    handleApplyFilters();
    setShowFilters(false);
  };

  const handleClearFilters = (): void => {
    clearFilters();
  };

  return (
    <PermissionsProvider
      permission={AfriexPermissions.RATES_PAGE}
      action={AfriexActions.CLICK_RATES_SEARCH_FILTER}
    >
      <div className="mt-5 w-full">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <div className="flex items-center space-x-2">
              <div className="flex items-center space-x-2">
                <Filter
                  closeFilter={() => setShowFilters(false)}
                  handleShowFilter={handleShowFilterChange}
                  label="Filters"
                  bodyClassName="w-96"
                >
                  {showFilters && (
                    <div className="p-4 space-y-4 max-w-4xl mx-auto w-inherit">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <DatePicker
                          selectedDate={fromDate}
                          setSelectedDate={(date) =>
                            selectAppliedFilters?.("fromDate", date)
                          }
                          placeholderText="Select From Date"
                          width={100}
                        />

                        <DatePicker
                          selectedDate={toDate}
                          setSelectedDate={(date) =>
                            selectAppliedFilters?.("toDate", date)
                          }
                          placeholderText="Select To Date"
                        />
                      </div>

                      {/* Action Buttons */}
                      <div className="flex justify-end">
                        <Button
                          onClick={handleClearFilters}
                          colorScheme="cyan"
                          variant="ghost"
                        >
                          Clear
                        </Button>

                        <Button
                          onClick={handleApplyFilter}
                          colorScheme="cyan"
                          className="ml-3"
                          disabled={!fromDate || !toDate}
                        >
                          Apply Filters {filterCount && ` (${filterCount})`}
                        </Button>
                      </div>
                    </div>
                  )}
                </Filter>
              </div>
            </div>

            {/* Status Filter */}
            <CustomSelect
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                selectCurrentFilters("currency", e.target.value)
              }
              value={currentFilters.currency}
              name="currency"
              options={getCurrencyOptions(currencyOptions)}
              placeholder="Filter by Currency"
            />
          </div>

          <div className="flex items-center space-x-2">
            <PermissionsProvider
              permission={AfriexPermissions.RATES_PAGE}
              action={AfriexActions.CLICK_RATES_OTC_TRADES_EXPORT}
            >
              <DownloadOTCTradeCSVButton />
            </PermissionsProvider>

            <PermissionsProvider
              permission={AfriexPermissions.RATES_PAGE}
              action={AfriexActions.CLICK_VIEW_OTC_TRADE_LOGS}
            >
              <Link
                to={`/rates/trade-logs`}
                target="_blank"
                rel="noopener noreferrer"
                className="w-max"
              >
                <Button
                  colorScheme="cyan"
                  variant="outline"
                  leftIcon={<ExternalLinkIcon className="w-5 h-5" />}
                >
                  View Logs
                </Button>
              </Link>
            </PermissionsProvider>

            <PermissionsProvider
              permission={AfriexPermissions.RATES_PAGE}
              action={AfriexActions.CLICK_RATES_ADD_UPDATE_OTC_TRADE}
            >
              <OTCActionButton />
            </PermissionsProvider>
          </div>
        </div>
      </div>
    </PermissionsProvider>
  );
}

export default OTCTradesFilters;
