import { AfriexActions, AfriexPermissions } from "../../../../types";
import { NotifyModalProps } from "../NotifyModal";
import { Metrics, MetricsData } from "../types";
import { formatTime } from "../utils";
import { PermissionsProvider, Button } from "../../../../components";

export interface SPCTableProps {
  metrics: Metrics;
  data: MetricsData[];
  setShowNotifyModal: (value: React.SetStateAction<boolean>) => void;
  setNotifyModalProps: (
    value: React.SetStateAction<
      Pick<
        NotifyModalProps,
        | "message"
        | "selectedProcessor"
        | "selectedCorridorPair"
        | "selectedPaymentInstrument"
      >
    >
  ) => void;
}
export const ratesHeaderHue = "bg-blue-50 p-8 shadow-sm sticky";
const SuccessPerCorridorTable = ({
  metrics,
  data,
  setNotifyModalProps,
  setShowNotifyModal,
}: SPCTableProps) => {
  return (
    <table
      className={`min-w-full divide-y divide-gray-200 border shadow-sm rounded-md`}
    >
      <thead className={ratesHeaderHue}>
        <tr>
          <th
            scope="col"
            className="py-4 px-3 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Corridor
          </th>

          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Time to Success (Avg)
          </th>
          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Prev Time to Success (Avg)
          </th>
          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            &#916; in Time to Success (Avg)
          </th>
          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Processor
          </th>

          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Success Rate (%)
          </th>

          <th
            scope="col"
            className="py-4 px-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          ></th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {Object.keys(metrics.corridorSuccessRates)
          .sort(
            (a, b) =>
              metrics.corridorSuccessRates[a] - metrics.corridorSuccessRates[b]
          )
          .map((corridorPair) => (
            <SPCTRow
              key={corridorPair}
              metrics={metrics}
              corridor={corridorPair}
              data={data}
              setNotifyModalProps={setNotifyModalProps}
              setShowNotifyModal={setShowNotifyModal}
            />
          ))}
      </tbody>
    </table>
  );
};

const SPCTRow = ({
  metrics,
  corridor,
  data,
  setShowNotifyModal,
  setNotifyModalProps,
}: {
  metrics: Metrics;
  corridor: string;
  data: MetricsData[];
  setShowNotifyModal: SPCTableProps["setShowNotifyModal"];
  setNotifyModalProps: SPCTableProps["setNotifyModalProps"];
}) => {
  const textClassName = "text-sm text-gray-800";
  const redTextClassName = "text-sm text-red-500";
  // Since we're filtering data in the parent, we should have exactly one processor per corridor
  const processor = data.filter((d) => d.corridor_pair === corridor)[0]
    ?.processor;
  const avgDuration = metrics.avgDurationPerCorridor[corridor];
  const prevDuration = metrics.previousAvgDurationPerCorridor[corridor];
  const deltaDuration = metrics.deltaDurationPerCorridor[corridor];
  const successRate = metrics.corridorSuccessRates[corridor];

  return (
    <tr
      className={`transition-background transition-colors transition-opacity ease-in-out duration-500 ${successRate < 85 ? "bg-red-100 hover:bg-red-100" : "hover:bg-gray-100"}`}
    >
      <td
        className={`py-4 px-3 whitespace-nowrap text-left ${textClassName} font-medium`}
      >
        {corridor}
      </td>
      <td
        className={`py-4 px-2 whitespace-nowrap text-left ${textClassName} font-medium`}
      >
        {formatTime(avgDuration)}
      </td>
      <td
        className={`py-4 px-2 whitespace-nowrap text-left ${textClassName} font-medium`}
      >
        {formatTime(prevDuration)}
      </td>
      <td
        className={`py-4 px-2 whitespace-nowrap text-left font-medium ${deltaDuration > 0 ? redTextClassName : textClassName}`}
      >
        {formatTime(deltaDuration)}
      </td>
      <td
        className={`py-4 px-2 whitespace-nowrap text-left ${textClassName} font-medium`}
      >
        {processor}
      </td>
      <td
        className={`py-4 px-2 whitespace-nowrap text-left font-medium ${successRate < 85 ? redTextClassName : textClassName}`}
      >
        {successRate.toFixed(2)}
      </td>
      <td
        className={`py-4 px-2 whitespace-nowrap text-left ${textClassName} font-medium`}
      >
        <PermissionsProvider
          permission={AfriexPermissions.PROCESSORS_PAGE}
          action={AfriexActions.CLICK_PROCESSORS_NOTIFY}
        >
          <Button
            onClick={() => {
              if (!processor || !corridor) return;
              setNotifyModalProps({
                message: `We are currently experiencing delays on ${corridor} with a completion time of ${formatTime(avgDuration)}.`,
                selectedProcessor: processor,
                selectedCorridorPair: corridor,
                selectedPaymentInstrument: "all",
              });
              setShowNotifyModal(true);
            }}
            variant="solid"
            colorScheme="cyan"
            className={`${successRate < 50 ? "opacity-50" : ""}`}
            disabled={successRate < 50}
          >
            Notify
          </Button>
        </PermissionsProvider>
      </td>
    </tr>
  );
};

export default SuccessPerCorridorTable;
