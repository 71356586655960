import { AfriexAdminUser } from "./AfriexAdmin";
import { SupportedCountryCodes } from "./SupportedCountryCodes";
import { SupportedCurrencies } from "./SupportedCurrencies";
import { TransactionChannels } from "./Transaction";

export enum TierTypes {
  promotion = "promotion",
  tier = "tier",
}

export enum AfriexRateTierCode {
  NewUser = "NEW_USER",
  RegularUser = "REGULAR_USER",
  ChurnedUser = "CHURNED_USER",
  PowerUser = "POWER_USER",
  InactiveUser = "INACTIVE_USER",
  RegulatedDebit = "REGULATED_DEBIT",
  UnregulatedDebit = "UNREGULATED_DEBIT",
  ACHBank = "ACH_BANK",
}

export interface AfriexTier {
  id?: string;
  name: string;
  discount: number;
  isActive?: boolean;
  shouldSkipFee?: boolean;
  transactionCountThreshold: number;
  transactionValueThreshold?: number;
  code?: AfriexRateTierCode;
  countries?: SupportedCountryCodes[];
  type?: TierTypes;
  admin?: AfriexAdminUser;
  updatedAt?: Date;
  createdAt?: Date;
  userTotal?: number;
  transactionTotal?: number;
  currencies?: SupportedCurrencies[];
  sourceCurrencies?: SupportedCurrencies[];
  allowedSourceChannels?: TransactionChannels[];
  allowedDestChannels?: TransactionChannels[];
  allowedCountries?: SupportedCountryCodes[];
}
