export type ReceiptConfig = {
  title: string;
  userAcceptancePolicy: string;
  refundPolicyTitle: string;
  refundPolicyContent: string;
};

const receiptConfig: ReceiptConfig = {
  title: "Remittance Transaction Receipt",
  userAcceptancePolicy: "User acceptance policy duly agreed",
  refundPolicyTitle: "Refund Policy",
  refundPolicyContent:
    "Afriex is a product of Afriex Inc. and its affiliates, collectively referred to as Afriex. Afriex is a corporation registered in Delaware, USA. Afriex is a technology company, not a bank. Afriex is registered with the Financial Crimes Enforcement Network (FinCEN) as a Money Service Business (MSB) dealing in foreign exchange and money transmission, with MSB registration number 31000235098465. Afriex is a registered MSB with FINTRAC in Canada. Afriex is licensed by the Central Bank of Nigeria (CBN) as an International Money Transfer Operator.",
};

export default receiptConfig;
