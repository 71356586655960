import { doNothing } from "../../../constants/formatters";
import useCountryList from "../../../hooks/useCountryList";
import { AfriexPerson } from "../../../types";
import { camelToTitle } from "../../../utils/camelToTitle";
import { mapCountryCodeToFlags } from "../../../utils/countries";
import KYCImage from "./KYCImage";

type KYCIDPrimaryProps = {
  user: AfriexPerson;
};
const KYCIDPrimary = ({ user }: KYCIDPrimaryProps) => {
  const { countryList } = useCountryList();
  const country = user?.kyc?.primary?.issuingCountry?.toUpperCase() ?? "-";

  return (
    <div className="px-3 pt-3 my-8">
      <h3 className="font-semibold text-gray-900 mb-2">
        {"Primary ID".toLocaleUpperCase()}
      </h3>
      <hr className="mb-5" />

      <div className="flex flex-col bg-white rounded-lg w-full px-5 py-5 gap-5 border border-gray-200">
        <div className="flex flex-wrap gap-3">
          {user?.kyc?.media?.images.map((img, idx) => {
            return (
              <KYCImage
                key={idx}
                src={img}
                alt={`image-${idx}`}
                removeAction={doNothing}
                name={user?.name?.fullName}
              />
            );
          })}
          {user?.kyc?.images?.front && (
            <div className="flex items-start gap-2">
              <KYCImage
                src={user?.kyc?.images?.front}
                alt={user?.kyc?.primary?.idType}
                name={user?.name?.fullName}
              />
            </div>
          )}
          {user?.kyc?.images?.back && (
            <div className="flex items-start gap-2 ml-3">
              <KYCImage
                src={user?.kyc?.images?.back}
                alt={user?.kyc?.secondary?.idType}
                name={user?.name?.fullName}
              />
            </div>
          )}
        </div>

        <div className="bg-white rounded-lg w-full">
          <div>
            {/* ID Type */}
            <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
              <p className="text-slate-500 text-sm">ID Type</p>
              <p className="text-sm font-medium text-gray-700 w-1/2">
                {user?.kyc?.primary?.idType?.toUpperCase() ?? "-"}
              </p>
            </div>

            {/* ID Number */}
            <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
              <p className="text-slate-500 text-sm">ID Number</p>
              <p className="text-sm font-medium text-gray-700 w-1/2">
                {user?.kyc?.primary?.idNumber ?? "-"}
              </p>
            </div>

            {/* Country of Issue */}
            <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
              <p className="text-slate-500 text-sm">Country of Issue</p>
              <p className="text-sm font-medium text-gray-700 w-1/2">
                {`${country} ${mapCountryCodeToFlags(countryList)[country] ?? ""}`}
              </p>
            </div>

            {/* Verification Status */}
            <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
              <p className="text-slate-500 text-sm">Verification</p>
              <p className="text-sm font-medium text-gray-700 w-1/2">
                {camelToTitle(user?.kyc?.primary?.status ?? "-")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KYCIDPrimary;
