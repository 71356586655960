import { AfriexCountry } from "../types";
import { stripeCountries } from "../constants/countries";

export const mapCountryCodeToFlags = (countryList: AfriexCountry[]) => {
  return countryList.reduce(
    (acc: Record<string, string>, country: AfriexCountry) => {
      acc[country.iso2] = country.flag as string;
      return acc;
    },
    {}
  );
};

export const mapCountryListToOptions = (countryList: AfriexCountry[]) => {
  return countryList.reduce(
    (acc: Record<string, string>, country: AfriexCountry) => {
      acc[country.iso2] = `${country.name} ${country.flag}`;
      return acc;
    },
    {}
  );
};

export const mapCountryListToCurrencies = (
  countryList: AfriexCountry[]
): Record<string, string> => {
  return countryList.reduce(
    (acc: Record<string, string>, country: AfriexCountry) => {
      if (country.currency) {
        acc[country.currency] = `${country.name} ${country.flag}`;
      }
      return acc;
    },
    {}
  );
};

export function isStripeSupportedCountry(country: string): boolean {
  return stripeCountries.includes(country?.toUpperCase());
}
