import { Metrics, MetricsData } from "./types";
import Corridor from "./Corridor";
import Processor from "./Processor";
import PaymentInstrument from "./PaymentInstrument";

const MetricsDashboard = ({
  data,
  metrics,
}: {
  metrics?: Metrics;
  data?: MetricsData[];
}) => {
  if (!data) return null;
  return (
    <main className="container mx-auto p-4">
      <p>Metrics are based on the last 1 hour of transactions.</p>
      <br />
      {metrics && (
        <section>
          <Corridor metrics={metrics} data={data} />
          <Processor metrics={metrics} />
          <PaymentInstrument metrics={metrics} />
        </section>
      )}
    </main>
  );
};

export default MetricsDashboard;
