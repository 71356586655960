import { sortBy, uniqBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import useCountryList from "../../../hooks/useCountryList";
import {
  ProcessorResponse,
  processorService,
  UpdateProcessorRequest,
} from "../../../services/processorService";
import { SupportedCurrencies } from "../../../types";
import { TransactionProcessors } from "../../../types/ProcessorTypes";
import { showErrorMessage } from "../../../utils/showErrorMessage";
import { showSuccessMessage } from "../../../utils/showSuccessMessage";

const useProcessorList = () => {
  const [processorsLatest, setProcessorsLatest] = useState<ProcessorResponse>(
    {}
  );
  const [processorsAll, setProcessorsAll] = useState<TransactionProcessors[]>(
    []
  );
  const [isSearch] = useState<boolean>(false);
  const [isPageReset] = useState<boolean>(false);
  const [showPagination] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [error, setError] = useState(null);
  const [selectedCurrency, setSelectedCurrency] =
    useState<SupportedCurrencies>();
  const [shouldShowUpdateForm, setShouldShowUpdateForm] =
    useState<boolean>(false);

  const { countryList, isLoading: isCountryListLoading } = useCountryList();
  const filteredCountryList = uniqBy(
    sortBy(countryList?.filter((c) => c.status === "active") ?? [], "currency"),
    "currency"
  );

  const fetchProcessorAll = useCallback(async () => {
    try {
      const processors = await processorService.getProcessorAll();
      processors.sort();
      setProcessorsAll(processors);
    } catch (e) {
      showErrorMessage(`Fetching all processor list failed:${e as any}`);
    }
  }, []);

  const fetchProcessorLatest = useCallback(async () => {
    try {
      const processors = await processorService.getProcessorLatest();
      setProcessorsLatest(processors);
    } catch (e) {
      showErrorMessage(`Fetching latest processor list failed:${e as any}`);
    }
  }, []);

  useEffect(() => {
    fetchProcessorAll();
    fetchProcessorLatest();
  }, [fetchProcessorAll, fetchProcessorLatest]);

  const handleUpdateProcessor = useCallback(
    async (processorRequest: UpdateProcessorRequest) => {
      try {
        setError(null);
        const processor =
          await processorService.updateProcessors(processorRequest);
        if (processor) {
          showSuccessMessage("Processor updated successfully");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      } catch (e) {
        showErrorMessage(`Updating processor failed:${e as any}`);
      }
    },
    []
  );

  const toggleProcessorUpdateForm = useCallback(() => {
    setShouldShowUpdateForm(!shouldShowUpdateForm);
  }, [shouldShowUpdateForm]);

  const showProcessorUpdateForm = useCallback(
    (currency: SupportedCurrencies) => {
      setSelectedCurrency(currency);
      toggleProcessorUpdateForm();
    },
    [toggleProcessorUpdateForm]
  );

  return {
    error,
    filteredCountryList,
    isLoading: isCountryListLoading,
    isSearch,
    setPage,
    pageInfo: {
      page,
      total: filteredCountryList?.length,
    },
    showPagination,
    countryList: countryList?.filter((c) => c.status === "active"),
    isPageReset,
    handleUpdateProcessor,
    processorsLatest,
    processorsAll,
    selectedCurrency,
    shouldShowUpdateForm,
    showProcessorUpdateForm,
    toggleProcessorUpdateForm,
  };
};

export default useProcessorList;
