import { Fragment } from "react";
import SubProcessorTable from "./SubProcessorTable";

import { Accordion } from "../../../components";

const ProcessorAccordion = ({ data: groupedByProcessor }: any) => {
  return (
    <Fragment>
      {Object.keys(groupedByProcessor).map((processor) => (
        <Accordion
          key={processor}
          title={`Processor: ${processor}`}
          headerStyle="bg-gray-200 shadow-sm text-white py-1.5"
        >
          <SubProcessorTable
            groupedByProcessor={groupedByProcessor}
            processor={processor}
          />
        </Accordion>
      ))}
    </Fragment>
  );
};

export default ProcessorAccordion;
