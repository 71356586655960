import { Header, Pagination } from "../../../components";
import { PaginationProps } from "../../../components/common/Pagination";
import TableSkeleton from "../../../components/Table/TableSkeleton";
import UserFilters from "./UserFilters";
import UserTable from "./UserTable";
import useUserList from "./useUserList";

const UserList = () => {
  const {
    isLoading,
    isSearch,
    isPageReset,
    fetchUserList,
    pageInfo,
    userList,
    filterCount,
    currentFilters,
    filtersToApply,
    handleApplyFilters,
    clearFilters,
    handleAutoComplete,
    selectAppliedFilters,
    selectCurrentFilters,
  } = useUserList();

  return (
    <main className="mt-10 mb-5 mx-4 md:mx-10">
      <Header title="Users" />

      <UserFilters
        filterCount={filterCount}
        currentFilters={currentFilters}
        filtersToApply={filtersToApply}
        clearFilters={clearFilters}
        selectCurrentFilters={selectCurrentFilters}
        selectAppliedFilters={selectAppliedFilters}
        handleApplyFilters={handleApplyFilters}
        handleAutoComplete={handleAutoComplete}
      />

      <section className="pt-5 md:pt-5">
        {isLoading ? <TableSkeleton /> : <UserTable items={userList} />}
      </section>

      {userList && userList.length ? (
        <Pagination
          onPageChange={fetchUserList as PaginationProps["onPageChange"]}
          total={pageInfo.total}
          isSearch={isSearch}
          isPageReset={isPageReset}
        />
      ) : null}
    </main>
  );
};

export default UserList;
