import {
  Modal,
  Button,
  SpinningLoader,
  Select,
  Input,
  TextArea,
} from "../../../components";
import { AfriexCountry, TransactionChannels } from "../../../types";
import { useCallback, useMemo, useState } from "react";
import { BlockedPaymentMethod } from "../../../services/blockedPaymentMethodService";
import { mapCountryCodeToFlags } from "../../../utils/countries";

type FormProps = {
  onClose: VoidFunction;
  handleSave: (body: BlockedPaymentMethod) => Promise<void>;
  countryList: AfriexCountry[];
};
type LabelType = Partial<Record<TransactionChannels, string>>;
export const transationTypeToLabel: LabelType = {
  [TransactionChannels.CARD]: "Debit Card",
  [TransactionChannels.BANK_ACCOUNT]: "Bank Account",
  [TransactionChannels.MOBILE_MONEY]: "Mobile Money",
};

export const transactionTypeToProvider: LabelType = {
  [TransactionChannels.CARD]: "Card Issuer",
  [TransactionChannels.BANK_ACCOUNT]: "Bank Name",
  [TransactionChannels.MOBILE_MONEY]: "Network Provider",
};

export const transactionTypeToIdentifier: LabelType = {
  [TransactionChannels.CARD]: "Card Number",
  [TransactionChannels.BANK_ACCOUNT]: "Account Number",
  [TransactionChannels.MOBILE_MONEY]:
    "Phone Number (in international format e.g. +123456789)",
};

const UpdateForm = ({ onClose, handleSave, countryList }: FormProps) => {
  const [formValues, setFormValues] = useState<BlockedPaymentMethod>(
    {} as BlockedPaymentMethod
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const label = useMemo(() => {
    return transationTypeToLabel?.[formValues.type] ?? "Payment Method";
  }, [formValues]);

  const providerLabel = useMemo(() => {
    return transactionTypeToProvider?.[formValues.type] ?? "Provider";
  }, [formValues]);

  const identifierLabel = useMemo(() => {
    return (
      transactionTypeToIdentifier?.[formValues.type] ?? "Account Identifier"
    );
  }, [formValues?.type]);

  const countryOptions = useMemo(
    () =>
      countryList.map((p) => ({
        label: `${p.name}${mapCountryCodeToFlags(countryList)[p.iso2]}`, // p.name,
        value: p.iso2,
      })),
    [countryList]
  );

  const transactionChannelOptions = [
    {
      label: "Debit Card",
      value: TransactionChannels.CARD,
    },
    {
      label: "Bank Account",
      value: TransactionChannels.BANK_ACCOUNT,
    },
    {
      label: "Mobile Money",
      value: TransactionChannels.MOBILE_MONEY,
    },
  ];

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    await handleSave(formValues);
    setIsLoading(false);
  }, [formValues, handleSave]);

  return (
    <Modal modalOpen>
      <div className="w-96 my-3 mx-5">
        <h3 className="text-lg  border-bottom">Add {label} To Blocked List</h3>
        {isLoading ? (
          <div className="flex w-full h-full justify-center items-center my-5">
            <SpinningLoader />
          </div>
        ) : (
          <>
            <form onSubmit={handleSubmit} className="flex flex-col space-y-5">
              <div className="mt-5">
                <Select
                  options={countryOptions as any}
                  label="Country"
                  onChange={(e: any) =>
                    setFormValues((x) => ({
                      ...x,
                      country: e?.target?.value ?? "",
                    }))
                  }
                />
              </div>

              <div className="mt-5">
                <Select
                  options={transactionChannelOptions as any}
                  label="Transaction Channel"
                  onChange={(e: any) =>
                    setFormValues((x) => ({
                      ...x,
                      type: e?.target?.value ?? "",
                    }))
                  }
                />
              </div>
              <div className="mt-5">
                <Input
                  type="text"
                  label={providerLabel}
                  onChange={(e: any) =>
                    setFormValues((x) => ({
                      ...x,
                      provider: e?.target?.value ?? "",
                    }))
                  }
                />
              </div>

              <div className="mt-5">
                <Input
                  type="text"
                  label={identifierLabel}
                  onChange={(e: any) =>
                    setFormValues((x) => ({
                      ...x,
                      accountIdentifier: e?.target?.value ?? "",
                    }))
                  }
                />
              </div>

              <div className="mt-5">
                <TextArea
                  label="Reason"
                  onChange={(e: any) =>
                    setFormValues((x) => ({
                      ...x,
                      reason: e?.target?.value ?? "",
                    }))
                  }
                />
              </div>

              <div className="flex w-full justify-end space-x-2 py-5">
                <Button onClick={onClose} variant="outline">
                  Cancel
                </Button>
                <Button
                  disabled={isLoading}
                  type="submit"
                  variant="outline"
                  className="bg-indigo-200 hover:g"
                >
                  Submit
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </Modal>
  );
};

export default UpdateForm;
