import PaymentInstrumentRateTable from "./PaymentInstrumentRateTable";
import { Metrics } from "../types";

const PaymentInstrument = ({ metrics }: { metrics: Metrics }) => {
  return (
    <section className="pt-5 md:pt-5">
      <h1 className="text-lg font-semibold mb-2">
        Payment Instrument Success Rate
      </h1>

      <PaymentInstrumentRateTable metrics={metrics} />
    </section>
  );
};

export default PaymentInstrument;
