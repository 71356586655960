import { AfriexParams } from "./AfriexQueryParams";

export const enum AfriexTelemetrySubject {
  User = "USER",
  Admin = "ADMIN",
  System = "SYSTEM",
}

export const enum AfriexTelemetryAction {
  Created = "CREATED",
  Updated = "UPDATED",
  Deleted = "DELETED",
  Credited = "CREDITED",
  Debited = "DEBITED",
  Blocked = "BLOCKED",
  Unblocked = "UNBLOCKED",
  LoggedIn = "LOGGED_IN",
  LoggedOut = "LOGGED_OUT",
  Started = "STARTED",
  Completed = "COMPLETED",
  Failed = "FAILED",
  Referred = "REFERRED",
  Verified = "VERIFIED",
  Unverified = "UNVERIFIED",
  Submitted = "SUBMITTED",
  Approved = "APPROVED",
}

export const enum AfriexTelemetryObject {
  Account = "ACCOUNT",
  Address = "ADDRESS",
  Bank = "BANK",
  Card = "CARD",
  Deposit = "DEPOSIT",
  Document = "DOCUMENT",
  Email = "EMAIL",
  KYC = "KYC",
  MobileMoney = "MOBILE_MONEY",
  OTCVolume = "OTC_VOLUME",
  Phone = "PHONE",
  Promo = "PROMO",
  Rate = "RATE",
  Swap = "SWAP",
  Transaction = "TRANSACTION",
  Transfer = "TRANSFER",
  User = "USER",
  Wallet = "WALLET",
  Withdrawal = "WITHDRAWAL",
}

export type AfriexTelemetryTag =
  | AfriexTelemetrySubject
  | AfriexTelemetryAction
  | AfriexTelemetryObject
  | string;

export interface AfriexTelemetry {
  id: string;
  description: string;
  tags: AfriexTelemetryTag[];
  meta?: Record<string, unknown>;
  createdAt?: string;
  updatedAt?: string;
}

export type AfriexLogParams = AfriexParams<{ tags: AfriexTelemetryTag[] }>;
