import axios from "../api/axios";
import { AfriexLogParams, AfriexPaginatedResponse } from "../types";
import { AfriexTelemetry } from "../types";
import { replaceUnderScoreIds } from "../utils/replaceUnderScoreIds";

export const logService = {
  getLogList: async (
    filters: AfriexLogParams
  ): Promise<AfriexPaginatedResponse<AfriexTelemetry[]>> => {
    const queryParams = {
      page: 0,
      limit: 10,
    } as any;

    if (!filters.tags.length) {
      return {
        data: [],
        page: 0,
        limit: 10,
        total: 0,
      };
    }

    const params = {
      ...queryParams,
      ...filters,
      tags: filters.tags.join(","),
    };
    const urlParams = new URLSearchParams(params).toString();
    const url = `/v2/admin/logs/telemetry?${urlParams}`;
    const response = await axios.get(url);
    const data = replaceUnderScoreIds<AfriexTelemetry>(response.data?.data);
    const logsData = { ...response.data, data } as AfriexPaginatedResponse<
      AfriexTelemetry[]
    >;
    return logsData;
  },
};
