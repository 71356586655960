import { useState } from "react";
import { Button } from "../../../components";
import ImageModal from "../../../components/CustomModal/ImageModal";
import { ZoomInIcon, ZoomOutIcon } from "@heroicons/react/outline";

interface KYCImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  showRemoveBtn?: boolean;
  removeAction?: () => void;
  name?: string;
}

const KYCImage = ({
  src,
  alt,
  showRemoveBtn,
  removeAction,
  name,
}: KYCImageProps) => {
  const [showFullScreen, setShowFullScreen] = useState(false);
  const toggleFullScreen = () => setShowFullScreen(!showFullScreen);

  const button = (
    <Button onClick={toggleFullScreen} variant="unStyled">
      <div
        className={`flex justify-center rounded hover:bg-cyan-50 ${
          showFullScreen && "bg-cyan-50"
        }`}
      >
        {showFullScreen ? (
          <ZoomOutIcon className="w-6 h-6 text-gray-700" />
        ) : (
          <ZoomInIcon className="text-gray-700 w-6 h-6 hover:text-gray-700" />
        )}
      </div>
    </Button>
  );
  const width = showFullScreen ? "100%" : "200px";
  const imageView = (
    <div className="flex flex-col place-items-start gap-2">
      <div className="flex justify-between w-full items-center">
        {button}
        {showRemoveBtn && (
          <Button
            onClick={removeAction}
            variant="unStyled"
            className="text-red-500"
          >
            remove
          </Button>
        )}
      </div>

      <img
        width={width}
        src={
          src ||
          `https://ui-avatars.com/api/?background=random&name=${name || "none"}`
        }
        alt={alt}
        onDoubleClick={toggleFullScreen}
      />
    </div>
  );

  return showFullScreen ? (
    <ImageModal
      isOpen={showFullScreen}
      onClose={toggleFullScreen}
      content={imageView}
    />
  ) : (
    imageView
  );
};

export default KYCImage;
