import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { ChangeEvent, useEffect, useState } from "react";
import currencies from "../../constants/currencies";
import { ICurrency } from "../../types/Currency";
import useCountryList from "../../hooks/useCountryList";
import { mapCountryCodeToFlags } from "../../utils/countries";
import { EUFlag } from "../../constants/countries";

type TransactionFiltersProps = {
  selectedCurrency: ICurrency | null;
  onCurrencyChange: (name: string, currency: ICurrency) => void;
  onAmountChange: (name: string, value: number) => void;
  name: string;
  amount: number;
  className?: string;
};

export default function CurrencyInput({
  selectedCurrency,
  onAmountChange,
  onCurrencyChange,
  amount,
  name,
  className,
}: TransactionFiltersProps): JSX.Element {
  const { countryList } = useCountryList();
  const [showCountries, setShowCountries] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<number>(amount);

  const selectCurrency = (currency: ICurrency) => {
    onCurrencyChange(name, currency);
    setShowCountries(false);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    setInputValue(value);
    onAmountChange(name, value);
  };

  useEffect(() => {
    if (amount !== inputValue) setInputValue(amount);
    // do not add inputValue to dependencies. just ignore the eslint warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  return (
    <div className={`relative ${className}`}>
      <div className="flex w-full relative">
        {/* from */}
        <span className="absolute top-0 left-0 pt-1 px-2.5 text-sm text-gray-500">
          {name}
        </span>
        <input
          onChange={handleChange}
          type="number"
          value={inputValue}
          name={name}
          className="h-12 pb-0 pt-4 border-gray-300  rounded text-gray-900 bg-gray-100  focus:ring-indigo-500 focus:bg-white focus:border-blue-500 block flex-1 min-w-0 w-full sm:text-sm p-2.5"
          placeholder="0.00"
        />
        {/* to */}
        <span className="absolute bottom-0 right-0 pr-2 hover:text-indigo-500">
          <button
            className="inline-flex items-center mb-1"
            onClick={() => setShowCountries(!showCountries)}
          >
            {!selectedCurrency && (
              <span className="text-sm text-gray-800 font-medium">
                Select Currency
              </span>
            )}
            <ChevronDownIcon className="h-5 w-5 hover:text-indigo-500" />
            {selectedCurrency && (
              <React.Fragment>
                {selectedCurrency.code}
                {
                  mapCountryCodeToFlags(countryList)[
                    selectedCurrency.countryCode
                  ]
                }
              </React.Fragment>
            )}
          </button>
        </span>
      </div>
      {/* options */}
      {showCountries && (
        <div
          onMouseLeave={() => setShowCountries(false)}
          className="absolute z-10 -top-2 right-0 rounded bg-white py-2 border shadow-md"
        >
          <ul>
            {currencies.map((currency, index) => (
              <li key={`${currency.countryCode}-${index}`}>
                <button
                  onClick={() => selectCurrency(currency)}
                  key={currency.code}
                  className="hover:bg-gray-100 active:bg-indigo-200 w-full px-3 py-2 text-left"
                >
                  {currency.code === "EUR"
                    ? EUFlag
                    : mapCountryCodeToFlags(countryList)[currency.countryCode]}
                  &nbsp; {currency.code}
                  &nbsp;-&nbsp;
                  {currency.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
