import React, { useState, useEffect } from "react";
import { Button, CustomModal, CustomSelect } from "../../../components";
import { CustomInput, CustomTextArea } from "../../../components/common/Input";
import {
  getCorridorPairsOptions,
  getPaymentInstrumentsOptions,
  getProcessorsOptions,
} from "../../../constants/formatters";

export type NotifyModalProps = {
  message?: string;
  processors: string[];
  selectedProcessor: string;
  corridorPairs: string[];
  selectedCorridorPair: string;
  paymentInstruments: string[];
  selectedPaymentInstrument: string;
  onSubmit: (data: NotifyModalData) => void;
  onClose: () => void;
  isOpen: boolean;
};

export type NotifyModalData = {
  title?: string;
  message: string;
  ttl: string;
  selectedProcessor: string;
  selectedCorridorPair?: string; // undefined if all
  selectedPaymentInstrument?: string; // undefined if all
};

const NotifyModal = ({
  message: initialMessage,
  selectedCorridorPair: corridorPair,
  selectedPaymentInstrument: paymentInstrument,
  selectedProcessor: processor,
  processors,
  corridorPairs,
  paymentInstruments,
  onSubmit,
  onClose,
  isOpen,
}: NotifyModalProps) => {
  console.log("initialMessage:::", initialMessage);
  console.log("corridorPair:::", corridorPair);
  console.log("paymentInstrument:::", paymentInstrument);
  console.log("processor:::", processor);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState(initialMessage || "");
  const [ttl, setTtl] = useState("2hrs");
  const [selectedProcessor, setSelectedProcessor] = useState(processor);
  const [selectedCorridorPair, setSelectedCorridorPair] =
    useState(corridorPair);
  const [selectedPaymentInstrument, setSelectedPaymentInstrument] =
    useState(paymentInstrument);

  // Update state when props change
  useEffect(() => {
    setMessage(initialMessage || "");
    setSelectedProcessor(processor);
    setSelectedCorridorPair(corridorPair);
    setSelectedPaymentInstrument(paymentInstrument);
  }, [initialMessage, processor, corridorPair, paymentInstrument]);

  console.log("message:::", message);
  console.log("selectedProcessor:::", selectedProcessor);
  console.log("selectedCorridorPair:::", selectedCorridorPair);
  console.log("selectedPaymentInstrument:::", selectedPaymentInstrument);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // clean up
    const corridorPair =
      selectedCorridorPair === "all" ? undefined : selectedCorridorPair;
    const paymentInstrument =
      selectedPaymentInstrument === "all"
        ? undefined
        : selectedPaymentInstrument;

    onSubmit({
      title,
      message,
      ttl,
      selectedProcessor,
      selectedCorridorPair: corridorPair,
      selectedPaymentInstrument: paymentInstrument,
    });
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      width="w-2/5"
      closeOnOutsideClick
      title="Downtime Notification"
      zIndex={11}
    >
      <div className="container mx-auto p-4">
        <div className="z-20">
          <h6> Displayed as callout on mobile</h6>

          {/*Callout*/}
          <div className="items-center flex">
            <div className="flex items-center rounded-lg border-2  border-gray-200 px-4 py-3 text-sm w-96 bg-yellow-100">
              <div className="">
                <div className="text-sm font-bold ">
                  <span className="mr-2">&#9888;</span>
                  {title}
                </div>
                <div className="mt-1 text-xs">{message}</div>
              </div>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <CustomInput
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Corridor Pair Downtime"
            className="w-full"
            required
          />

          <CustomTextArea
            label="Message"
            name="message"
            required
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            aria-rowspan={4}
            type="text"
            className="w-full isFull"
          />

          <div className="flex space-x-3">
            <CustomSelect
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                setSelectedProcessor(e.target.value)
              }
              value={selectedProcessor}
              name="processor"
              options={getProcessorsOptions(processors)}
              placeholder="Select"
              label="Processor"
              className="w-1/3"
              required
            />

            <CustomSelect
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                setSelectedCorridorPair(e.target.value)
              }
              value={selectedCorridorPair}
              name="corridorPair"
              options={getCorridorPairsOptions(corridorPairs)}
              placeholder="Select Corridor Pair"
              label="Corridor Pair"
              required
            />

            <CustomSelect
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                setSelectedPaymentInstrument(e.target.value)
              }
              value={selectedPaymentInstrument}
              name="paymentInstrument"
              options={[
                { key: "All", value: "all" },
                ...getPaymentInstrumentsOptions(paymentInstruments),
              ]}
              placeholder="Select Payment Instrument"
              label="Payment Instrument"
              required
            />
          </div>

          <CustomSelect
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setTtl(e.target.value)
            }
            value={ttl}
            name="ttl"
            options={[
              { key: "2hrs", value: "2hrs" },
              { key: "1hr", value: "1hr" },
              { key: "30mins", value: "30mins" },
            ]}
            placeholder="Select"
            label="TTL: (Expires after)"
            required
          />

          <div className="flex justify-end mt-4">
            <Button type="submit" variant="solid" colorScheme="cyan">
              Create Notification
            </Button>
          </div>
        </form>
      </div>
    </CustomModal>
  );
};

export default NotifyModal;
