import { useCallback, useEffect, useState } from "react";
import { transactionService } from "../../../services/transactionService";
import {
  AfriexPaginatedResponse,
  AfriexQueryParams,
  AfriexTransaction,
  AfriexTransactionStatus,
} from "../../../types";
import { TransactionListProps } from "./types";
import { formatAdminTransaction } from "../../../utils/dashboard";
import { useLocation } from "react-router-dom";
import useFilters from "../../../hooks/useFilters";
import { omit } from "lodash";

const useUserTransactionList = (userId: string): TransactionListProps => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status") as AfriexTransactionStatus;

  const [transactionList, setTransactionList] = useState<AfriexTransaction[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSearch] = useState<boolean>(false);
  const [transactionTotal, setTransactionTotal] = useState<number>(0);
  const [showPagination] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [isPageReset, setIsPageReset] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const [currentFilters, setCurrentFilters] = useState<AfriexQueryParams>({});
  const [transactionSummary, setTransactionSummary] =
    useState<AfriexPaginatedResponse<AfriexTransaction[]>["summary"]>();
  const [filtersToApply, setFiltersToApply] = useState<AfriexQueryParams>({});
  const [filterCount, setFilterCount] = useState(0);
  const { applyFilters } = useFilters(setCurrentFilters);

  const fetchAllUserTransactions = useCallback(
    async (params?: AfriexQueryParams) => {
      try {
        setError(null);
        setIsLoading(true);

        const { data, total, summary } =
          await transactionService.getUserTransactionList(userId, {
            ...params,
            ...currentFilters,
          });

        const filtered = await Promise.all(
          data &&
            data.map(async (transaction) => {
              const at = await formatAdminTransaction(transaction);
              return {
                ...transaction,
                type: at.type,
                adminUser: at.adminUser,
              };
            })
        );

        setTransactionList(filtered);
        setTransactionTotal(total ?? 0);
        setTransactionSummary(summary);
      } catch (error) {
        setError(error as any);
      } finally {
        setIsLoading(false);
      }
    },
    [currentFilters, userId]
  );

  useEffect(() => {
    fetchAllUserTransactions({});
  }, [fetchAllUserTransactions]);

  useEffect(() => {
    if (status) {
      setCurrentFilters((prev) => ({ ...prev, status }));
    } else {
      const updatedFilters = omit(currentFilters, ["status"]);
      setCurrentFilters(updatedFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleApplyFilters = useCallback(() => {
    applyFilters(filtersToApply);
    setIsPageReset((prev) => !prev);
  }, [applyFilters, filtersToApply]);

  // Handle change for user manipulation
  const selectCurrentFilters = useCallback(
    (name: string, value: string | Date | undefined) => {
      setCurrentFilters((prev) => {
        const updatedFilters = {
          ...prev,
          [name]: value,
        };
        setFilterCount(Object.keys(updatedFilters).length);
        return updatedFilters;
      });
      setIsPageReset((prev) => !prev);
    },
    []
  );

  // Handle dates for applied filters
  const selectAppliedFilters = useCallback(
    (name: string, value: string | Date | number | undefined) => {
      setFiltersToApply((prev) => {
        const updatedFilters = {
          ...prev,
          [name]: value,
        };
        // Only count non-undefined values
        const filteredCount = Object.entries(updatedFilters).filter(
          ([_, val]) => val !== undefined
        ).length;
        setFilterCount(filteredCount);
        return updatedFilters;
      });
    },
    []
  );

  const cleanFilters = () => {
    setCurrentFilters({});
    setFiltersToApply({});
    setFilterCount(0);
    setIsPageReset((prev) => !prev);
    if (status) setCurrentFilters({ status });
  };

  return {
    error,
    fetchTransactionList: fetchAllUserTransactions,
    isLoading,
    isSearch,
    setPage,
    pageInfo: {
      page,
      total: transactionTotal,
      summary: transactionSummary,
    },
    showPagination,
    transactionList,
    filterCount,
    isPageReset,
    currentFilters,
    selectCurrentFilters,
    filtersToApply,
    selectAppliedFilters,
    handleApplyFilters,
    clearFilters: cleanFilters,
  };
};

export default useUserTransactionList;
