import ProcessorAccordion from "./ProcessorAccordion";
import CurrencyAccordion from "./CurrencyAccordion";

const ProcessorMetricsGrouped = ({ data }: any) => {
  const { groupedByCurrency, groupedByProcessor } = data ?? {};
  if (!groupedByCurrency || !groupedByProcessor) return null;
  return (
    <div className="container mx-auto p-4">
      <div>
        <div>
          <h2 className="text-lg font-bold mb-2">Grouped by Processor</h2>
          <ProcessorAccordion data={data.groupedByProcessor} />
        </div>
        <div className="mt-8" />
        <div>
          <h2 className="text-lg font-bold mb-2">Grouped by Currency</h2>
          <CurrencyAccordion data={data.groupedByCurrency} />
        </div>
      </div>
    </div>
  );
};

export default ProcessorMetricsGrouped;
