import {
  AfriexTier,
  SupportedCountryCodes,
  SupportedCurrencies,
  TransactionChannels,
} from "../../../types";
import { AfriexQueryParams } from "../../../types/AfriexQueryParams";

export type TierListProps<T> = {
  error: any;
  fetchList: (params: AfriexQueryParams) => void;
  isLoading: boolean;
  page: number;
  setPage: (page: number) => void;
  total: number;
  list: T[];
};

export enum TierActionType {
  UPDATE_TIER = "UPDATE_TIER",
  ADD_SUPPORTED_SOURCE_CURRENCY = "ADD_SUPPORTED_SOURCE_CURRENCY",
  REMOVE_SUPPORTED_SOURCE_CURRENCY = "REMOVE_SUPPORTED_SOURCE_CURRENCY",
  ADD_SUPPORTED_CURRENCY = "ADD_SUPPORTED_CURRENCY",
  REMOVE_SUPPORTED_CURRENCY = "REMOVE_SUPPORTED_CURRENCY",
  ADD_SUPPORTED_SOURCE_PAYMENT_METHOD = "ADD_SUPPORTED_SOURCE_PAYMENT_METHOD",
  REMOVE_SUPPORTED_SOURCE_PAYMENT_METHOD = "REMOVE_SUPPORTED_SOURCE_PAYMENT_METHOD",
  ADD_SUPPORTED_TARGET_PAYMENT_METHOD = "ADD_SUPPORTED_TARGET_PAYMENT_METHOD",
  REMOVE_SUPPORTED_TARGET_PAYMENT_METHOD = "REMOVE_SUPPORTED_TARGET_PAYMENT_METHOD",
  ADD_SUPPORTED_COUNTRY = "ADD_SUPPORTED_COUNTRY",
  REMOVE_SUPPORTED_COUNTRY = "REMOVE_SUPPORTED_COUNTRY",
}

interface UpdateTierAction {
  type:
    | TierActionType.UPDATE_TIER
    | TierActionType.ADD_SUPPORTED_SOURCE_CURRENCY
    | TierActionType.REMOVE_SUPPORTED_SOURCE_CURRENCY
    | TierActionType.ADD_SUPPORTED_CURRENCY
    | TierActionType.REMOVE_SUPPORTED_CURRENCY
    | TierActionType.ADD_SUPPORTED_SOURCE_PAYMENT_METHOD
    | TierActionType.REMOVE_SUPPORTED_SOURCE_PAYMENT_METHOD
    | TierActionType.ADD_SUPPORTED_TARGET_PAYMENT_METHOD
    | TierActionType.REMOVE_SUPPORTED_TARGET_PAYMENT_METHOD
    | TierActionType.ADD_SUPPORTED_COUNTRY
    | TierActionType.REMOVE_SUPPORTED_COUNTRY;

  payload:
    | Partial<AfriexTier>
    | SupportedCurrencies
    | TransactionChannels
    | SupportedCountryCodes;
}

export type TierAction = UpdateTierAction;
