import { ExternalLinkIcon } from "@heroicons/react/outline";
import { Link } from "../../../components";
import { AfriexActions, AfriexPermissions, AfriexPerson } from "../../../types";
import UserProfile from "../Details/UserProfile";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";
// import { ExternalLinkIcon } from "@heroicons/react/outline";

type UserSummaryProp = {
  user: AfriexPerson;
  extraRow?: React.ReactNode;
};

const UserSummary = ({ user, extraRow }: UserSummaryProp) => {
  return (
    <div className="bg-white rounded-lg w-full px-5 sm:px-5 py-5 sm:py-5">
      <div className="flex items-center gap-4 mb-3">
        <h2 className="font-semibold text-2xl mr-3">{user.name?.fullName}</h2>
        <Link
          to={`/users/${user.id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm text-cyan-700 mr-2 hover:underline"
        >
          View User Profile
        </Link>

        <PermissionsProvider
          permission={AfriexPermissions.USERS_PAGE}
          action={AfriexActions.CLICK_VIEW_ACCOUNT_STATEMENT}
        >
          <Link
            to={`/transactions/user/${user.id}/statement`}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center text-sm text-cyan-700 gap-2"
          >
            <ExternalLinkIcon className="w-4 h-4" />
            View Statement
          </Link>
        </PermissionsProvider>
      </div>

      <UserProfile
        user={user}
        showUserID
        showPhone
        showPhoneVerified
        showReferredBy
        showSecurityEnabled
        showKYCStatus
        showLastLogin
        showViewTransactions
        showStripeUser
        showRewardPoints
        showInteracEmails
        showRegistrationIP
      />

      {extraRow ?? ""}
    </div>
  );
};

export default UserSummary;
