import { useState } from "react";
import OTCTradeModal from "./OTCTradeModal";
import { Button } from "../../../components";
import { isEmpty } from "lodash";
import { AfriexOTCTrade } from "../../../types";
import { PlusIcon } from "@heroicons/react/outline";

type OTCActionButtonProps = {
  initialData?: AfriexOTCTrade;
};

const OTCActionButton = ({ initialData }: OTCActionButtonProps) => {
  const [showFullScreen, setShowFullScreen] = useState(false);
  const updateLabel = isEmpty(initialData) ? "Add Trade" : "Update Trade";
  const toggleFullScreen = () => {
    setShowFullScreen(!showFullScreen);
  };

  return showFullScreen ? (
    <OTCTradeModal
      isOpen={showFullScreen}
      onClose={toggleFullScreen}
      initialData={initialData}
      title={updateLabel}
    />
  ) : (
    <Button
      {...{
        ...(updateLabel.includes("Add")
          ? { leftIcon: <PlusIcon className="w-5 h-5" /> }
          : {}),
      }}
      variant="solid"
      colorScheme="cyan"
      onClick={toggleFullScreen}
    >
      {updateLabel}
    </Button>
  );
};

export default OTCActionButton;
