import { formatTime } from "./utils";

const ratesHeaderHue = "bg-blue-50 p-8 shadow-sm sticky";
const SubProcessorTable = ({
  groupedByProcessor,
  processor,
}: {
  groupedByProcessor: any;
  processor: string;
}) => {
  return (
    <table
      className={`min-w-full w-full divide-y divide-gray-200 border shadow-sm rounded-md`}
    >
      <thead className={ratesHeaderHue}>
        <tr>
          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Corridor Pair
          </th>
          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Number of Transactions
          </th>
          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Success Rate (%)
          </th>
          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Average Time
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {Object.keys(groupedByProcessor[processor]).map(
          (corridorPair, index) => {
            return (
              <SubProcessorTRow
                key={index}
                corridorPair={corridorPair}
                index={index}
                groupedByProcessor={groupedByProcessor}
                processor={processor}
              />
            );
          }
        )}
      </tbody>
    </table>
  );
};

const SubProcessorTRow = ({
  index,
  processor,
  corridorPair,
  groupedByProcessor,
}: {
  groupedByProcessor: any;
  processor: string;
  index: number;
  corridorPair: string;
}) => {
  const textClassName = "text-sm text-gray-800";
  const zebraRow = index % 2 === 0 ? "" : "bg-gray-50";
  const totalTx =
    groupedByProcessor[processor][corridorPair].total_no_txn_per_p_cp_pi;
  const successTx = groupedByProcessor[processor][corridorPair].success_count;
  const totalDuration =
    groupedByProcessor[processor][corridorPair].total_duration;
  const successRate = (successTx / totalTx) * 100;
  const avgTime = successTx ? totalDuration / successTx : 0;

  return (
    <tr
      className={`transition-background transition-colors transition-opacity ease-in-out duration-500 hover:bg-gray-100 ${zebraRow}`}
    >
      <td
        className={`py-4 px-2 whitespace-nowrap text-left ${textClassName} font-medium`}
      >
        {corridorPair}
      </td>
      <td
        className={`py-4 px-2 whitespace-nowrap text-left ${textClassName} font-medium`}
      >
        {totalTx}
      </td>
      <td
        className={`py-4 px-2 whitespace-nowrap text-left ${textClassName} font-medium`}
      >
        {successRate.toFixed(2)}
      </td>
      <td
        className={`py-4 px-2 whitespace-nowrap text-left ${textClassName} font-medium`}
      >
        {formatTime(avgTime)}
      </td>
    </tr>
  );
};

export default SubProcessorTable;
