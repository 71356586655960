import { Accordion } from "../../../components";
import SubCurrencyTable from "./Processor/SubCurrencyTable";

const CurrencyAccordion = ({ data: groupedByCurrency }: any) => {
  return (
    <div>
      {Object.keys(groupedByCurrency).map((currency) => (
        <Accordion
          key={currency}
          title={`Currency: ${currency}`}
          headerStyle="bg-gray-200 shadow-sm text-white py-1.5"
        >
          <SubCurrencyTable data={groupedByCurrency} currency={currency} />
        </Accordion>
      ))}
    </div>
  );
};

export default CurrencyAccordion;
