import { MetricsData } from "./types";
import { formatTime } from "./utils";

export interface RawMetricsTableProps {
  data: MetricsData[];
}
export const ratesHeaderHue = "bg-blue-50 p-8 shadow-sm sticky";
const RawMetricsTable = ({ data }: RawMetricsTableProps) => {
  return (
    <table
      className={`min-w-full w-full divide-y divide-gray-200 border shadow-sm rounded-md`}
    >
      <thead className={ratesHeaderHue}>
        <tr>
          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Processor
          </th>
          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Corridor Pair
          </th>
          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Payment Instrument
          </th>
          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Status
          </th>
          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Total Transactions
          </th>
          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Successful Transactions
          </th>
          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Success Rate (%)
          </th>
          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Average Duration
          </th>
          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Previous Average Duration
          </th>
          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Delta Duration
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {data
          .sort((a, b) => a.percent_st_per_p_cp_pi - b.percent_st_per_p_cp_pi)
          .map((item, index) => (
            <RawMetricsTRow key={index} index={index} item={item} />
          ))}
      </tbody>
    </table>
  );
};

const RawMetricsTRow = ({
  item,
  index,
}: {
  item: MetricsData;
  index: number;
}) => {
  const textClassName = "text-sm text-gray-800";
  const zebraRow = index % 2 === 0 ? "" : "bg-gray-50";
  const {
    processor,
    corridor_pair,
    payment_instrument,
    status,
    total_no_txn_per_p_cp_pi,
    total_no_txn_per_p_cp_pi_st,
    percent_st_per_p_cp_pi,
    avg_duration_per_p_cp_pi_st,
    avg_duration_per_p_cp_pi_st_prev,
    delta_avg_duration_per_p_cp_pi_st,
  } = item;

  const successRate = percent_st_per_p_cp_pi;
  const avgDuration = avg_duration_per_p_cp_pi_st;
  const prevAvgDuration = avg_duration_per_p_cp_pi_st_prev;
  const deltaDuration = delta_avg_duration_per_p_cp_pi_st;

  return (
    <tr
      className={`transition-background transition-colors transition-opacity ease-in-out duration-500 hover:bg-gray-100 ${zebraRow}`}
    >
      <td
        className={`py-4 px-2 whitespace-nowrap text-left ${textClassName} font-medium`}
      >
        {processor}
      </td>
      <td
        className={`py-4 px-2 whitespace-nowrap text-left font-medium ${textClassName}`}
      >
        {corridor_pair.replace("-->", "::")}
      </td>
      <td
        className={`py-4 px-2 whitespace-nowrap text-left ${textClassName} font-medium`}
      >
        {payment_instrument}
      </td>
      <td
        className={`py-4 px-2 whitespace-nowrap text-left ${textClassName} font-medium`}
      >
        {status}
      </td>
      <td
        className={`py-4 px-2 whitespace-nowrap text-left ${textClassName} font-medium`}
      >
        {total_no_txn_per_p_cp_pi}
      </td>
      <td
        className={`py-4 px-2 whitespace-nowrap text-left ${textClassName} font-medium`}
      >
        {total_no_txn_per_p_cp_pi_st}
      </td>
      <td
        className={`py-4 px-2 whitespace-nowrap text-left ${textClassName} font-medium`}
      >
        {successRate.toFixed(2)}
      </td>
      <td
        className={`py-4 px-2 whitespace-nowrap text-left ${textClassName} font-medium`}
      >
        {formatTime(avgDuration)}
      </td>
      <td
        className={`py-4 px-2 whitespace-nowrap text-left ${textClassName} font-medium`}
      >
        {formatTime(prevAvgDuration ?? 0)}
      </td>
      <td
        className={`py-4 px-2 whitespace-nowrap text-left ${textClassName} font-medium`}
      >
        {formatTime(deltaDuration ?? 0)}
      </td>
    </tr>
  );
};

export default RawMetricsTable;
