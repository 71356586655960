import { useCallback, useEffect, useState } from "react";
import { referralService } from "../../../services/referralService";
import {
  AfriexQueryParams,
  AfriexReferral,
  ReferralListProps,
} from "../../../types";
import { showErrorMessage } from "../../../utils/showErrorMessage";
import { showSuccessMessage } from "../../../utils/showSuccessMessage";
import useFilters from "../../../hooks/useFilters";

const useReferralList = (): ReferralListProps => {
  const [referralList, setReferralList] = useState<AfriexReferral[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [itemTotal, setItemTotal] = useState<number>(0);
  const [showPagination, setShowPagination] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [isPageReset, setIsPageReset] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const [currentFilters, setCurrentFilters] = useState<AfriexQueryParams>({});
  const [filtersToApply, setFiltersToApply] = useState<AfriexQueryParams>({});
  const [filterCount, setFilterCount] = useState(0);
  const { applyFilters } = useFilters(setCurrentFilters);

  const fetchAllReferrals = useCallback(
    async (params?: AfriexQueryParams) => {
      try {
        setError(null);
        setIsLoading(true);
        const { data: referrals, total } =
          await referralService.getReferralList({
            ...params,
            ...currentFilters,
          });
        setReferralList(referrals);
        setItemTotal(total ?? 0);
      } catch (e) {
        setError(e as any);
      } finally {
        setIsLoading(false);
      }
    },
    [currentFilters]
  );

  useEffect(() => {
    fetchAllReferrals({});
  }, [fetchAllReferrals]);

  const handleApplyFilters = useCallback(() => {
    applyFilters(filtersToApply); // Apply the filters
    setIsPageReset((prev) => !prev); // Reset pagination to the first page
  }, [applyFilters, filtersToApply]);

  // Handle change for instant data fetching
  const selectCurrentFilters = useCallback(
    (name: string, value: string | Date | undefined) => {
      setCurrentFilters((prev) => {
        const updatedFilters = {
          ...prev,
          [name]: value,
        };
        return updatedFilters;
      });
      setIsPageReset((prev) => !prev);
    },
    []
  );

  const selectAppliedFilters = useCallback(
    (name: string, value: string | Date | undefined) => {
      setFiltersToApply((prev) => {
        const updatedFilters = {
          ...prev,
          [name]: value,
        };
        // Only count non-undefined values
        const filteredCount = Object.entries(updatedFilters).filter(
          ([_, val]) => val !== undefined
        ).length;
        setFilterCount(filteredCount);
        return updatedFilters;
      });
    },
    []
  );

  const handleAutoComplete = useCallback(
    async (searchTerm: string) => {
      try {
        setError(null);
        setIsLoading(true);
        setShowPagination(false);
        if (!searchTerm?.trim() || searchTerm.length < 3) {
          await fetchAllReferrals({});
          return;
        }
        const referrals = await referralService.searchAutoComplete(searchTerm);
        if (referrals) {
          setReferralList(referrals);
          setItemTotal(1);
          setIsSearch(true);
        }
      } catch (e) {
        setError(e as any);
      }
      setIsLoading(false);
    },
    [fetchAllReferrals]
  );

  const handlePayment = useCallback(async (referralId: string) => {
    try {
      setError(null);
      setIsLoading(true);
      const referral = await referralService.fulfillReferral(referralId);
      if (referral) {
        showSuccessMessage("Referral fulfilled successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      showErrorMessage(`Payment failed:${e as any}`);
    }
    setIsLoading(false);
  }, []);

  const handleBlockPayout = useCallback(async (referralId: string) => {
    try {
      setError(null);
      setIsLoading(true);
      const referral = await referralService.blockPayout(referralId);
      if (referral) {
        showSuccessMessage("Referral payout blocked successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      showErrorMessage(`Blocking payout failed:${e as any}`);
    }
    setIsLoading(false);
  }, []);

  const cleanFilters = () => {
    setCurrentFilters({});
    setFiltersToApply({});
    setFilterCount(0);
    setIsPageReset((prev) => !prev);
  };

  return {
    error,
    handlePayment,
    showPagination,
    handleBlockPayout,
    setPage,
    isLoading,
    isSearch,
    isPageReset,
    fetchReferralList: fetchAllReferrals,
    pageInfo: {
      page,
      total: itemTotal,
    },
    referralList,
    filterCount,
    currentFilters,
    filtersToApply,
    handleApplyFilters,
    clearFilters: cleanFilters,
    handleAutoComplete,
    selectAppliedFilters,
    selectCurrentFilters,
  };
};

export default useReferralList;
