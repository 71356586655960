import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { DownloadIcon } from "@heroicons/react/outline";
import { ratesService } from "../../../services/ratesService";
import { AfriexOTCTrade } from "../../../types";
import { Button } from "../../../components";
import { formatDateForFileName, getLast7DaysRange } from "../../../utils/date";
import Spinner from "../../../assets/svg/Spinner";

const DownloadOTCTradeCSVButton = () => {
  const [data, setData] = useState<AfriexOTCTrade[]>([]);
  const [loading, setLoading] = useState(true);
  const { fromDate, toDate } = getLast7DaysRange();

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: items } = await ratesService.getOTCTradeList({
          limit: 100,
          fromDate,
          toDate,
        });
        setData(items);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [fromDate, toDate]);

  const transformDataForCSV = (data: AfriexOTCTrade[]) => {
    return data.map(
      ({ submittedBy, approvedBy, fromSymbol, toSymbol, ...rest }) => ({
        fromCurrency: fromSymbol,
        toCurrency: toSymbol,
        ...rest,
        submittedByName: submittedBy?.name || "N/A",
        submittedByRole: submittedBy?.role || "N/A",
        approvedByName: approvedBy?.name || "N/A",
        approvedByRole: approvedBy?.role || "N/A",
        approvedByTimestamp: approvedBy?.timestamp || "N/A",
      })
    );
  };

  // Define CSV Headers
  const headers = Object.keys(transformDataForCSV(data)[0] || {}).map(
    (key) => ({
      label: key
        .replace(/([A-Z])/g, " $1") // Convert camelCase to readable format
        .replace(/^./, (str) => str.toUpperCase()), // Capitalize first letter
      key,
    })
  );

  return (
    <>
      {loading ? (
        <Spinner size={6} className="text-cyan-600" />
      ) : data.length ? (
        <>
          <Button colorScheme="cyan" variant="outline">
            <CSVLink
              data={transformDataForCSV(data as AfriexOTCTrade[])}
              headers={headers}
              filename={`${formatDateForFileName()}_otc_trades_${new Date().getTime()}.csv`}
            >
              <DownloadIcon className="h-5 w-5" />
            </CSVLink>
          </Button>
        </>
      ) : (
        <Button colorScheme="cyan" variant="outline" disabled>
          <DownloadIcon className="h-5 w-5" />
        </Button>
      )}
    </>
  );
};

export default DownloadOTCTradeCSVButton;
