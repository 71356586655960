import * as Yup from "yup";
import { isNumber } from "lodash";
import { FeePaymentMethod, TransactionTypes } from "../../types";

export const baseFeeSchema = Yup.object()
  .shape({
    baseValue: Yup.number()
      .when("percentValue", {
        is: (percentValue: string) => isNumber(percentValue),
        then: (schema) => schema.optional(),
        otherwise: (schema) => schema.required("Fixed value required"),
      })
      .optional(),
    percentValue: Yup.number().optional(),
    type: Yup.string().required().equals(["default"]).required(),
  })
  .noUnknown(true);

export const overrideFeeSchema = Yup.object().shape({
  baseValue: Yup.number()
    .when("percentValue", {
      is: (percentValue: string) => isNumber(percentValue),
      then: (schema) => schema.optional(),
      otherwise: (schema) =>
        schema.required("Fixed or Percentage value required"),
    })
    .optional(),
  percentValue: Yup.number().optional(),

  maxFeeValue: Yup.number().optional(),

  limit: Yup.number().when("limitType", {
    is: (limitType: string | undefined) =>
      ["above", "below"].includes(limitType?.toLowerCase() ?? ""),
    then: (schema) =>
      schema.required().when("maxFeeValue", {
        is: (maxFeeValue: number | undefined) => maxFeeValue !== undefined,
        then: (schema) =>
          schema.max(
            Yup.ref("maxFeeValue"),
            "Limit cannot be higher than max fee value"
          ),
      }),
    otherwise: (schema) =>
      schema.optional().when("maxFeeValue", {
        is: (maxFeeValue: number | undefined) => maxFeeValue !== undefined,
        then: (schema) =>
          schema.max(
            Yup.ref("maxFeeValue"),
            "Limit cannot be higher than max fee value"
          ),
      }),
  }),
  limitType: Yup.string().oneOf(["above", "below"]).optional(),
  paymentMethod: Yup.string().oneOf(Object.values(FeePaymentMethod)).optional(),
  currencyPair: Yup.string().notOneOf(["*:*", ""]).optional(),
  type: Yup.string().equals(["override"]).required(),
  transactionType: Yup.string()
    .oneOf(
      Object.values([
        TransactionTypes.DEPOSIT,
        TransactionTypes.WITHDRAW,
        TransactionTypes.SWAP,
        TransactionTypes.E2E,
        TransactionTypes.VIRTUAL_CARD_LOAD,
        TransactionTypes.VIRTUAL_CARD_SPEND,
        TransactionTypes.VIRTUAL_CARD_UNLOAD,
        TransactionTypes.ACH,
      ])
    )
    .required(),
});
