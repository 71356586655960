import { Tr, Td } from "../Table/Table";

export default function TableSkeleton(): JSX.Element {
  return (
    <>
      {Array(8)
        .fill(0)
        .map((item, index) => (
          <Tr key={index} className="hover:bg-slate-200 hover:bg-opacity-20">
            <Td>
              <div className="animate-pulse rounded bg-gray-200 h-3 w-10"></div>
            </Td>

            <Td>
              <div className="animate-pulse rounded bg-gray-200 h-3 w-8"></div>
            </Td>
            <Td>
              <div className="animate-pulse rounded bg-gray-200 h-3 w-8"></div>
            </Td>
            <Td>
              <div className="animate-pulse rounded bg-gray-200 h-3 w-36"></div>
            </Td>
            <Td>
              <div className="animate-pulse rounded bg-gray-200 h-3 w-16"></div>
            </Td>
          </Tr>
        ))}
    </>
  );
}
