import { Button } from "../../../components";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";
import { EUFlag } from "../../../constants/countries";
import currencies from "../../../constants/currencies";
import { ProcessorResponse } from "../../../services/processorService";
import {
  AfriexActions,
  AfriexCountry,
  AfriexPermissions,
  SupportedCurrencies,
} from "../../../types";
import { mapCountryCodeToFlags } from "../../../utils/countries";
import { ProcessorTableProps } from "./ProcessorTable";

type SummaryProp = {
  item: AfriexCountry;
  processors: ProcessorResponse;
  currencyCode: SupportedCurrencies;
  corridors: AfriexCountry[];
  showProcessorUpdateForm: ProcessorTableProps["showProcessorUpdateForm"];
  countryList: AfriexCountry[];
};

const ProcessorSummary = ({
  item,
  processors,
  corridors,
  currencyCode,
  showProcessorUpdateForm,
  countryList,
}: SummaryProp) => {
  const depositProcessors = processors?.DEPOSIT?.[currencyCode]
    ?.split(",")
    ?.map((x) => x.trim());
  const withdrawalProcessors = processors?.WITHDRAW?.[currencyCode]
    ?.split(",")
    ?.map((x) => x.trim());
  const defaultDepositProcessor = depositProcessors?.[0] ?? "n/a";
  const defaultWithdrawalProcessor = withdrawalProcessors?.[0] ?? "n/a";
  const extraDepositProcessors = depositProcessors?.filter(
    (x) => x !== defaultDepositProcessor
  );
  const extraWithdrawalProcessors = withdrawalProcessors?.filter(
    (x) => x !== defaultWithdrawalProcessor
  );

  const euroCurrency = currencies.find((currency) => currency.code === "EUR");
  const flag =
    item?.currency === euroCurrency?.code
      ? EUFlag
      : mapCountryCodeToFlags(countryList)[item?.iso2];

  return (
    <div className="bg-white rounded-lg w-full px-5 sm:px-5 py-5 sm:py-5">
      <div className="flex items-center gap-4 mb-3">
        <h2 className="font-semibold text-2xl mr-3">
          Processors for {currencyCode} {flag}
        </h2>
      </div>
      <div>
        {defaultDepositProcessor && (
          <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
            <p className="text-slate-500 text-sm">Deposit Processor</p>
            <p className="text-sm font-medium text-gray-700 w-2/3 whitespace-normal overflow-hidden">
              {defaultDepositProcessor?.toUpperCase()}
            </p>
          </div>
        )}

        {(extraDepositProcessors?.length ?? 0) > 0 && (
          <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
            <p className="text-slate-500 text-sm">Other Deposit Processor(s)</p>
            <p className="text-sm font-medium text-gray-700 w-2/3 whitespace-normal overflow-hidden">
              {extraDepositProcessors?.join(", ")?.toUpperCase()}
            </p>
          </div>
        )}

        {defaultWithdrawalProcessor && (
          <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
            <p className="text-slate-500 text-sm">Withdrawal Processor</p>
            <p className="text-sm font-medium text-gray-700 w-2/3 whitespace-normal overflow-hidden">
              {defaultWithdrawalProcessor?.toUpperCase()}
            </p>
          </div>
        )}

        {(extraWithdrawalProcessors?.length ?? 0) > 0 && (
          <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
            <p className="text-slate-500 text-sm">
              Other Withdrawal Processor(s)
            </p>
            <p className="text-sm font-medium text-gray-700 w-2/3 whitespace-normal overflow-hidden">
              {extraWithdrawalProcessors?.join(", ")?.toUpperCase()}
            </p>
          </div>
        )}

        <div className="flex justify-between mb-2 py-2 px-3 bg-gray-100">
          <p className="text-slate-500 text-sm">Countries Affected</p>
          <p className="text-sm font-medium text-gray-700 w-2/3 whitespace-normal overflow-hidden">
            {corridors?.map((c) => c?.name).join(", ")}
          </p>
        </div>

        <div className="flex items-center justify-end gap-6 mt-4">
          <PermissionsProvider
            permission={AfriexPermissions.PROCESSORS_PAGE}
            action={AfriexActions.CLICK_PROCESSORS_UPDATE}
          >
            <Button
              data-tip
              data-for="payoutButton"
              onClick={() => showProcessorUpdateForm(currencyCode)}
              variant={"solid"}
              colorScheme="cyan"
            >
              Update Processor
            </Button>
          </PermissionsProvider>
        </div>
      </div>
    </div>
  );
};

export default ProcessorSummary;
