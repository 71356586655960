import { ReactElement } from "react";
import { AfriexCountry } from "../../types";
import useCountryList from "../../hooks/useCountryList";
import { matches, filter } from "lodash";
import { CustomSelect } from "..";
import { SelectProps } from "../common/Input";
import { mapCountryCodeToFlags } from "../../utils/countries";

interface CountryPickersProps extends Omit<SelectProps, "options"> {
  options?: { key: string; value: string }[];
  filter?: Partial<AfriexCountry>;
}

const mapOptionsToList = (
  options: AfriexCountry[],
  filters: Partial<AfriexCountry> = {}
) =>
  filter(options, matches(filters)).map((country: AfriexCountry) => ({
    value: country.iso2,
    key: `${country.name} ${mapCountryCodeToFlags(options)[country?.iso2] ?? ""}`,
  }));

const CountryPicker = ({
  filter = {},
  ...props
}: CountryPickersProps): ReactElement => {
  const { countryList } = useCountryList();
  const options = mapOptionsToList(countryList ?? [], filter);
  return <CustomSelect {...props} options={options} />;
};
export default CountryPicker;
