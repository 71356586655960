import { capitalize } from "lodash";
import { ICurrency } from "../../../types/Currency";
import { AfriexPerson } from "../../../types";
import { LedgerReasons } from "../../../types/Transaction";

export interface ConfirmationLabelType {
  action: "credit" | "debit";
  amount: number;
  currency: ICurrency | null;
  user: AfriexPerson;
  fundDebitReason: LedgerReasons | undefined;
}

const ConfirmationLabel = ({
  action,
  amount,
  currency,
  user,
  fundDebitReason,
}: ConfirmationLabelType) => (
  <div className="flex flex-col gap-3">
    <h3>
      Are you sure you want to {action} &nbsp;
      <span className="text-indigo-500 text-center">
        {amount} {currency?.code}
      </span>{" "}
      {action === "credit" ? "to " : "from "} {user.name?.firstName}{" "}
      {user.name?.lastName}?
    </h3>
    <p className="text-sm font-medium">
      Reason:{" "}
      <span
        className={`font-regular ${
          action === "credit" ? "text-successful-500" : "text-failed-500"
        }`}
      >
        {capitalize(fundDebitReason?.replace(/_/g, " "))}
      </span>
    </p>
    <div className="text-sm font-light mt-3">
      Double check the amount before clicking confirm
    </div>
  </div>
);

export default ConfirmationLabel;
