import { Link } from "../../../components";
import { AfriexPerson } from "../../../types";
import UserProfile from "../../User/Details/UserProfile";

export type KYCSummaryProp = { user: AfriexPerson; extraRow?: React.ReactNode };

const KYCSummary = ({ user, extraRow }: KYCSummaryProp) => {
  return (
    <div className="bg-white rounded-lg w-full px-5 sm:px-5 py-5 sm:py-5">
      <div className="flex items-center gap-4 mb-3">
        <h2 className="font-semibold text-2xl mr-3">{user.name?.fullName}</h2>
        <Link
          to={`/kyc/${user.id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm text-cyan-700 mr-2 hover:underline"
        >
          View KYC Details
        </Link>
      </div>

      <UserProfile
        type="kyc"
        user={user}
        showEmail
        showUserID
        showKYCStatus
        showRegistrationIP
        showKycProcessors
      />

      {extraRow ?? ""}
    </div>
  );
};

export default KYCSummary;
