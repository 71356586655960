import { uniq } from "lodash";
import NotifyModal, { NotifyModalData, NotifyModalProps } from "../NotifyModal";
import SuccessPerCorridorTable from "./PerCorridorTable";
import { useState } from "react";
import { processorService } from "../../../../services/processorService";
import { toast } from "react-toastify";
import { Metrics, MetricsData } from "../types";

const Corridor = ({
  metrics,
  data,
}: {
  metrics: Metrics;
  data: MetricsData[];
}) => {
  const [showNotifyModal, setShowNotifyModal] = useState(false);
  const [notifyModalProps, setNotifyModalProps] = useState<
    Pick<
      NotifyModalProps,
      | "message"
      | "selectedProcessor"
      | "selectedCorridorPair"
      | "selectedPaymentInstrument"
    >
  >({
    message: "",
    selectedProcessor: "",
    selectedCorridorPair: "",
    selectedPaymentInstrument: "",
  });

  const createNotifyModal = async (data: NotifyModalData) => {
    try {
      await processorService.createDowntimeNotification(data);
      setShowNotifyModal(false);
      toast.success("Notification created");
    } catch (error) {
      toast.error("Error creating notification");
    }
  };

  // Get unique processors and corridors from data
  const processors = uniq(data.map((item) => item.processor));
  const corridorPairs = uniq(data.map((item) => item.corridor_pair));
  const paymentInstruments = uniq(data.map((item) => item.payment_instrument));

  return (
    <section className="pt-5 md:pt-5">
      <h1 className="text-lg font-semibold mb-2">
        Success Rate % per Corridor
      </h1>

      <SuccessPerCorridorTable
        metrics={metrics}
        data={data}
        setShowNotifyModal={setShowNotifyModal}
        setNotifyModalProps={setNotifyModalProps}
      />

      <NotifyModal
        {...notifyModalProps}
        processors={processors}
        corridorPairs={corridorPairs}
        paymentInstruments={paymentInstruments}
        onSubmit={createNotifyModal}
        onClose={() => setShowNotifyModal(false)}
        isOpen={showNotifyModal}
      />
    </section>
  );
};

export default Corridor;
