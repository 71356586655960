import { useCallback, useEffect, useReducer, useState } from "react";
import { tierService } from "../../../services/tierService";
import {
  AfriexRateInfo,
  AfriexTier,
  SupportedCurrencies,
} from "../../../types";
import { showErrorMessage } from "../../../utils/showErrorMessage";
import { showSuccessMessage } from "../../../utils/showSuccessMessage";
import updateTierReducer from "./updateTierReducer";
import { isEmpty, pick } from "lodash";
import { applyDiscountToRates } from "../../../utils/rates/applyDiscountToRates";
import { convertRatesListToMap } from "../../../utils/rates/convertRatesListToMap";
import { TierActionType } from "./types";
import useRatesUpdate from "../Upload/useRatesUpdate";

const useTierUpdate = (
  onClose: VoidFunction,
  initialData: Partial<AfriexTier> = {},
  rateInfo?: AfriexRateInfo
) => {
  const {
    handleArbitrageCheck,
    arbitrageResult,
    isArbitrageDetected,
    isArbitrageChecked,
    handleToleranceChange,
    tolerance,
  } = useRatesUpdate();
  const [tier, dispatch] = useReducer(
    updateTierReducer,
    initialData as AfriexTier
  );
  const [isLoading, setIsLoading] = useState(false);
  const [shouldShowArbitrageTable, setShouldShowArbitrageTable] =
    useState<boolean>(false);
  useEffect(() => {
    dispatch({
      type: TierActionType.UPDATE_TIER,
      payload: initialData,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkIfRateTierHasArbitrage = useCallback(async (): Promise<void> => {
    const appRates = rateInfo?.appRates ?? [];

    if (isEmpty(appRates) || appRates.length === 0) {
      showErrorMessage("No rates fetched. Please,refresh the page");
      return;
    }
    const ratesWithDiscount = applyDiscountToRates(
      rateInfo?.appRates ?? [],
      Math.abs(Number(tier.discount)),
      tier.currencies
    );
    const rates = convertRatesListToMap(ratesWithDiscount);
    const currencySymbols = Object.keys(rates) as SupportedCurrencies[];
    await handleArbitrageCheck(rates, currencySymbols);
    setShouldShowArbitrageTable(true);
  }, [handleArbitrageCheck, rateInfo, tier.discount]);

  const handleSubmit = async (rawTier: AfriexTier) => {
    setIsLoading(true);
    try {
      if (!rawTier.name) {
        showErrorMessage(`Please provide the name of the promo`);
        setIsLoading(false);
        return;
      }
      if (!rawTier.discount) {
        showErrorMessage(`Please provide the discount value`);
        setIsLoading(false);
        return;
      }
      if (rawTier.id) {
        const fieldsToInclude: (keyof AfriexTier)[] = [
          "name",
          "discount",
          "transactionCountThreshold",
          "transactionValueThreshold",
          "sourceCurrencies",
          "allowedCountries",
          "currencies",
          "allowedSourceChannels",
          "allowedDestChannels",
          "isActive",
          "shouldSkipFee",
          "code",
        ];
        await tierService.updateTier(
          rawTier.id,
          pick(rawTier, fieldsToInclude) as AfriexTier
        );
        showSuccessMessage(`Promo tier updated successfully`);
      } else {
        await tierService.createTier(rawTier);
        showSuccessMessage(`Promo tier saved successfully`);
      }

      onClose();

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (e) {
      showErrorMessage(`Error saving Tier ${e}`);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    arbitrageResult,
    checkIfRateTierHasArbitrage,
    dispatch,
    handleSubmit,
    isLoading,
    isArbitrageDetected,
    isArbitrageChecked,
    handleToleranceChange,
    tolerance,
    shouldShowArbitrageTable,
    setShouldShowArbitrageTable,
    tier,
  };
};

export default useTierUpdate;
