import { capitalize } from "lodash";
import {
  AfriexAdmin,
  AfriexLocationInfo,
  AfriexOTCRate,
  AfriexPaginatedResponse,
  AfriexPerson,
  AfriexReferralStatus,
  AfriexRewardTier,
  AfriexRewardType,
  AfriexRoles,
  AfriexTransaction,
  SupportedCurrencies,
} from "../types";
import {
  DisplayAfriexTransactionStatus,
  FeeTransactionTypes,
  LedgerReasons,
  TransactionChannels,
  TransactionTypes,
} from "../types/Transaction";
import { capitalizeWords, formatValues } from "../utils/dashboard";
import { TransactionProcessors } from "../types/ProcessorTypes";
import { AfriexDisputeStatus } from "../types/AfriexDispute";
import {
  AfriexBlockReason,
  AfriexUnblockReason,
} from "../types/AfriexBlockReason";
import { DashCardConfig } from "../config/menuConfig";

export const doNothingAsync = () => Promise.resolve();
export const doNothing = () => null;

export const getCountryOptions = (countryOptions: Record<string, string>) =>
  Object.keys(countryOptions).map((key) => {
    const displayKey = countryOptions[key as keyof typeof countryOptions];
    return {
      key: displayKey?.replace(/undefined/g, "").trim(),
      value: key,
    };
  });

export const getCurrencyOptions = (currencyOptions: Record<string, string>) =>
  Object.keys(currencyOptions)
    .filter((currency) => currency in SupportedCurrencies) // Only include supported currencies
    .map((currency) => {
      const displayKey =
        currencyOptions[currency as keyof typeof currencyOptions];
      return {
        key: displayKey?.replace(/undefined/g, "").trim(),
        value: currency,
      };
    });

export const getTransactionChannelsOptions = () =>
  Object.entries(TransactionChannels)
    .map(([key, value]) => ({
      key: capitalizeWords(key?.replace(/_/g, " ")),
      value,
    }))
    .sort((a, b) => a.key.localeCompare(b.key));

export const getTransactionProcessorsOptions = () =>
  Object.entries(TransactionProcessors)
    .map(([key, value]) => ({
      key: capitalizeWords(key?.replace(/_/g, " ")),
      value,
    }))
    .sort((a, b) => a.key.localeCompare(b.key));

export const getTransactionTypesOptions = () =>
  Object.entries(TransactionTypes).map(([key, value]) => ({
    key: capitalizeWords(key?.replace(/_/g, " ")),
    value,
  }));

export const getFeeTransactionTypesOptions = () =>
  Object.entries(FeeTransactionTypes).map(([key, value]) => ({
    key: capitalizeWords(key?.replace(/_/g, " ")),
    value,
  }));

export const getTransactionStatusOptions = () =>
  Object.entries(DisplayAfriexTransactionStatus).map(([key, value]) => ({
    key: capitalizeWords(key?.replaceAll(/_/g, " ")),
    value,
  }));

export const getAdminRoleOptions = (excludedRoles = [] as AfriexRoles[]) => {
  return Object.entries(AfriexRoles)
    .filter(([_, value]) => !excludedRoles.includes(value as AfriexRoles)) // Exclude the 'USER' and 'ENGINEERING_MANAGER role
    .map(([key, value]) => ({
      key: capitalizeWords(key.replace(/_/g, " ")), // Format key nicely
      value,
    }));
};

export const getReferralStatusOptions = () =>
  Object.keys(AfriexReferralStatus).map((key) => ({
    key: capitalize(key),
    value: AfriexReferralStatus[key as keyof typeof AfriexReferralStatus],
  }));

export const getDisputeStatusOptions = () =>
  Object.keys(AfriexDisputeStatus).map((key) => ({
    key: capitalize(key),
    value: AfriexDisputeStatus[key as keyof typeof AfriexDisputeStatus],
  }));

export const getRewardTypeOptions = () =>
  Object.keys(AfriexRewardType).map((key) => ({
    key: key,
    value: AfriexRewardType[key as keyof typeof AfriexRewardType],
  }));

export const getRewardTierOptions = () =>
  Object.keys(AfriexRewardTier).map((key) => ({
    key: key,
    value: AfriexRewardTier[key as keyof typeof AfriexRewardTier],
  }));

export const getLedgerReasonOptions = () => {
  return Object.keys(LedgerReasons).map((key) => {
    const value = LedgerReasons[key as keyof typeof LedgerReasons];
    return {
      key: capitalizeWords(value.replace(/_/g, " ")),
      value,
    };
  });
};

export const getProcessorsOptions = (processors: string[]) =>
  Object.entries(processors)
    .map(([_, value]) => ({
      key: capitalizeWords(value?.replace(/_/g, " ")),
      value,
    }))
    .sort((a, b) => a.key.localeCompare(b.key));

export const getCorridorPairsOptions = (corridorPairs: string[]) =>
  Object.entries(corridorPairs)
    .map(([_, value]) => ({
      key: value,
      value,
    }))
    .sort((a, b) => a.key.localeCompare(b.key));

export const getPaymentInstrumentsOptions = (instruments: string[]) =>
  instruments
    .map((value) => ({
      key: value,
      value,
    }))
    .sort((a, b) => a.key.localeCompare(b.key));

export const getTransactionAmountandAsset = (
  transaction: AfriexTransaction,
  type = "money" as DashCardConfig["valueType"]
) => {
  const isWithdrawal = transaction?.type === TransactionTypes.WITHDRAW;
  const isAdmin = transaction.channel === TransactionChannels.ADMIN;
  const amount = (
    isAdmin
      ? transaction.sourceAmount
      : isWithdrawal
        ? transaction.destinationAmount
        : transaction.sourceAmount
  ) as number;
  const asset = isAdmin
    ? transaction.sourceAsset
    : isWithdrawal
      ? transaction.destinationAsset
      : transaction.sourceAsset;

  return { amount: formatValues(amount, type), asset };
};

export const modifyAdminProfiles = (
  adminProfiles: AfriexPaginatedResponse<AfriexAdmin[]>[],
  users: AfriexPerson[]
): AfriexAdmin[] => {
  const usersMap = new Map<string, string>();
  users.forEach((user) => {
    usersMap.set(user.id, user.name.fullName);
  });

  const modifiedAdmins: AfriexAdmin[] = adminProfiles
    .flatMap((page) => page.data)
    .map((admin) => ({
      ...admin,
      fullName: usersMap.get(admin.userId) || "N/A",
    }));

  return modifiedAdmins;
};

export const getEarliestLocation = (locations: AfriexLocationInfo[]) => {
  if (!Array.isArray(locations) || locations.length === 0) {
    return null;
  }

  return locations.reduce((earliest, current) => {
    const earliestDate = new Date(earliest.timestamp);
    const currentDate = new Date(current.timestamp);
    return currentDate < earliestDate ? current : earliest;
  });
};

export const AfriexBlockReasonsMap = [
  {
    key: "Sanctions Match",
    value: AfriexBlockReason.SANCTIONS_MATCH,
  },
  {
    key: "Identity Theft",
    value: AfriexBlockReason.IDENTITY_THEFT,
  },
  {
    key: "Multiple Disputes",
    value: AfriexBlockReason.MULTIPLE_DISPUTES,
  },
  {
    key: "Social Engineering",
    value: AfriexBlockReason.SOCIAL_ENGINEERING,
  },
  {
    key: "Positive Dow Jones match",
    value: AfriexBlockReason.POSITIVE_DOW_JONES_MATCH,
  },
  {
    key: "Confirmed fraudulent activity",
    value: AfriexBlockReason.CONFIRMED_FRAUDULENT_ACTIVITY,
  },
  {
    key: "Fraudulent activity investigation ongoing",
    value: AfriexBlockReason.FRAUDULENT_ACTIVITY_INVESTIGATION_ONGOING,
  },
  {
    key: "Account takeover case",
    value: AfriexBlockReason.ACCOUNT_TAKEOVER_CASE,
  },
  {
    key: "Unauthorized transactions investigation completed",
    value: AfriexBlockReason.UNAUTHORIZED_TRANSACTIONS_INVESTIGATION_COMPLETED,
  },
  {
    key: "Identity theft case",
    value: AfriexBlockReason.IDENTITY_THEFT_CASE,
  },
  {
    key: "ACH fraud",
    value: AfriexBlockReason.ACH_FRAUD,
  },
  {
    key: "Others",
    value: AfriexBlockReason.OTHERS,
  },
];

export const AfriexUnblockReasonsMap = [
  {
    key: "EDD Provided",
    value: AfriexUnblockReason.EDD_PROVIDED,
  },
  {
    key: "KYC Place",
    value: AfriexUnblockReason.KYC_IN_PLACE,
  },

  {
    key: "Watchlist Match Cleared",
    value: AfriexUnblockReason.WATCHLIST_MATCH_CLEARED,
  },
  {
    key: "Dispute Funds Recovered From Wallet",
    value: AfriexUnblockReason.DISPUTE_FUNDS_RECOVERED_FROM_WALLET,
  },
  {
    key: "Dispute Resolved",
    value: AfriexUnblockReason.DISPUTE_RESOVED,
  },
  {
    key: "Cards reviewed on stripe",
    value: AfriexUnblockReason.CARDS_REVIWED_ON_STRIPE,
  },
  {
    key: "Date of birth updated",
    value: AfriexUnblockReason.DATE_OF_BIRTH_UPDATED,
  },
  {
    key: "Legal names updated",
    value: AfriexUnblockReason.LEGAL_NAMES_UPDATED,
  },
  {
    key: "Others",
    value: AfriexUnblockReason.OTHERS,
  },
];

export const removeEmptyRows = (arr: AfriexOTCRate[]) =>
  arr?.filter((item) => item.fromSymbol || item.toSymbol || item.value) ?? [];
