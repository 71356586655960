import { Button } from "../../../components";
import { ClipboardLabel } from "../../../components";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";
import { formatValues } from "../../../utils/dashboard";
import {
  AfriexActions,
  AfriexOTCTrade,
  AfriexPermissions,
} from "../../../types";
import * as formatDate from "../../../utils/date";
import { formatName } from "../../../utils/formatName";
import OTCActionButton from "./OTCActionButton";

type OTCTradeSummaryProp = {
  item: AfriexOTCTrade;
  handleApprove: (id: string) => void;
  extraRow?: React.ReactNode;
};

const OTCTradeSummary = ({
  item,
  handleApprove,
  extraRow,
}: OTCTradeSummaryProp) => {
  return (
    <div className="bg-white rounded-lg w-full px-5 sm:px-5 py-5 sm:py-5">
      <div className="flex items-center gap-4 mb-3">
        <h2 className="font-semibold text-xl mr-3">OTC Trade Details</h2>
      </div>

      <div>
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Reference ID</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            <ClipboardLabel>
              {item?.id ?? (item as any)?._id ?? ""}
            </ClipboardLabel>
          </p>
        </div>

        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Date of Input</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {formatDate.DDMonYYYY(item?.createdAt?.toString() ?? "")}
          </p>
        </div>

        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Transaction DateTime</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {formatDate.DDMonYYYY(item?.timestamp?.toString() ?? "", true)}
          </p>
        </div>

        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Amount Sent</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {item.fromAmount?.toLocaleString()} {item.fromSymbol}
          </p>
        </div>

        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Amount Received</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {item.toAmount?.toLocaleString()} {item.toSymbol}
          </p>
        </div>

        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Balance Remaining</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {item.availableAmount?.toLocaleString()} {item.toSymbol}
          </p>
        </div>

        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Rate</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {formatValues(Number(item.rate), "rate", 6)}
          </p>
        </div>

        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Submitted By</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {formatName(item.submittedBy)}
          </p>
        </div>

        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Approved By</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {item?.approvedBy ? (
              formatName(item.approvedBy)
            ) : (
              <span className="text-red-400">Not Approved</span>
            )}
          </p>
        </div>
      </div>

      <div className="text-sm text-slate-500 w-full pt-5 sm:pt-5">
        <div className="flex justify-end gap-4">
          {!item?.approvedBy && (
            <PermissionsProvider
              permission={AfriexPermissions.RATES_PAGE}
              action={AfriexActions.CLICK_RATES_APPROVE}
            >
              <Button
                variant="solid"
                colorScheme="green"
                onClick={() => {
                  handleApprove(item.id);
                  return false;
                }}
              >
                Approve
              </Button>
            </PermissionsProvider>
          )}

          <PermissionsProvider
            permission={AfriexPermissions.RATES_PAGE}
            action={AfriexActions.CLICK_RATES_ADD_UPDATE_OTC_TRADE}
          >
            {!item?.approvedBy?.id ? (
              <OTCActionButton initialData={item} />
            ) : null}
          </PermissionsProvider>
        </div>
      </div>

      {extraRow ?? ""}
    </div>
  );
};

export default OTCTradeSummary;
