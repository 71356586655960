import { useCallback, useRef } from "react";
import { identity } from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
import { AfriexLogParams, AfriexQueryParams } from "../types";

export type queryParamsType = AfriexQueryParams | AfriexLogParams;
const useFilters = <T extends queryParamsType>(
  setFilters: React.Dispatch<React.SetStateAction<T>>
) => {
  const validDateRef = useRef(false);

  const applyFilters = useCallback(
    (filters: T) => {
      // Start with a safe copy that maintains required fields (like tags)
      const cleanedFilters = { ...filters } as Partial<T>;

      // Remove null, undefined, empty string values (similar to pickBy but preserving structure)
      Object.keys(filters).forEach((key) => {
        if (!identity(filters[key as keyof T])) {
          delete cleanedFilters[key as keyof T];
        }
      });

      // Check if both fromDate and toDate are present
      if (cleanedFilters.fromDate && cleanedFilters.toDate) {
        // Validate dates
        validDateRef.current = moment(cleanedFilters.toDate).isSameOrAfter(
          moment(cleanedFilters.fromDate)
        );

        if (validDateRef.current) {
          // Format dates properly
          cleanedFilters.fromDate = moment(cleanedFilters.fromDate)
            .startOf("day")
            .toISOString();

          cleanedFilters.toDate = moment(cleanedFilters.toDate)
            .endOf("day")
            .toISOString();
        } else {
          toast.error("From Date cannot be after To Date");
          delete cleanedFilters.fromDate;
          delete cleanedFilters.toDate;
        }
      } else if (cleanedFilters.fromDate || cleanedFilters.toDate) {
        // If only one date is provided, we should show an error
        toast.error("Both From Date and To Date are required");
        delete cleanedFilters.fromDate;
        delete cleanedFilters.toDate;
      }

      setFilters(cleanedFilters as T);
    },
    [setFilters]
  );

  return { applyFilters };
};

export default useFilters;
