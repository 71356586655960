import { Metrics } from "../types";

export interface PITableProps {
  metrics: Metrics;
}
export const ratesHeaderHue = "bg-blue-50 p-8 shadow-sm sticky";
const PaymentInstrumentRateTable = ({ metrics }: PITableProps) => {
  return (
    <table
      className={`min-w-full divide-y divide-gray-200 border shadow-sm rounded-md`}
    >
      <thead className={ratesHeaderHue}>
        <tr>
          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Payment Instrument
          </th>

          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Success Rate (%)
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {Object.keys(metrics.paymentInstrumentSuccessRates)
          .sort(
            (a, b) =>
              metrics.paymentInstrumentSuccessRates[a] -
              metrics.paymentInstrumentSuccessRates[b]
          )
          .map((paymentInstrument, index) => (
            <PITRow
              key={paymentInstrument}
              index={index}
              metrics={metrics}
              paymentInstrument={paymentInstrument}
            />
          ))}
      </tbody>
    </table>
  );
};

const PITRow = ({
  metrics,
  paymentInstrument,
  index,
}: {
  metrics: Metrics;
  paymentInstrument: string;
  index: number;
}) => {
  const textClassName = "text-sm text-gray-800";
  const zebraRow = index % 2 === 0 ? "" : "bg-gray-50";
  return (
    <tr
      className={`transition-background transition-colors transition-opacity ease-in-out duration-500 hover:bg-gray-100 ${zebraRow}`}
    >
      <td
        className={`py-4 px-2 whitespace-nowrap text-left ${textClassName} font-medium`}
      >
        {paymentInstrument}
      </td>
      <td
        className={`py-4 px-2 whitespace-nowrap text-left font-medium ${textClassName}`}
      >
        {metrics.paymentInstrumentSuccessRates[paymentInstrument].toFixed(2)}
      </td>
    </tr>
  );
};

export default PaymentInstrumentRateTable;
