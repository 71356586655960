import { useEffect, useMemo, useState } from "react";
import CustomModal from "../../../components/CustomModal";
import { UpdateProcessorRequest } from "../../../services/processorService";
import { TransactionProcessors } from "../../../types/ProcessorTypes";
import { SupportedCurrencies, TransactionTypes } from "../../../types";
import Spinner from "../../../assets/svg/Spinner";
import { Button, Select } from "../../../components";

interface UpdateProcessorModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleUpdate: (data: UpdateProcessorRequest) => void;
  processors: TransactionProcessors[];
  currency: SupportedCurrencies;
}

const UpdateProcessorModal = ({
  isOpen,
  onClose,
  processors,
  currency,
  handleUpdate,
}: UpdateProcessorModalProps) => {
  const [transactionType, setTransactionType] = useState<TransactionTypes>(
    TransactionTypes.DEPOSIT
  );
  const [processor, setProcessor] = useState<TransactionProcessors>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const processorOptions = useMemo(
    () =>
      processors.map((p) => ({
        label: p,
        value: p,
      })),
    [processors]
  );

  const transactionTypeOptions = [
    {
      label: "Deposit",
      value: "DEPOSIT",
    },
    {
      label: "Withdrawal",
      value: "WITHDRAW",
    },
  ];

  const handleSubmit = async () => {
    const payload: UpdateProcessorRequest = {
      currency,
      type: transactionType as TransactionTypes,
      processorName: processor as TransactionProcessors,
    };
    setIsLoading(true);
    await handleUpdate(payload);
    setIsLoading(false);
  };

  useEffect(() => {
    setProcessor(processors[0]);
    setTransactionType(TransactionTypes.DEPOSIT);
  }, [processors]);

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      width="w-5/6 md:w-4/6 lg:w-6/12 xl:w-5/12 2xl:w-4/12"
      closeOnOutsideClick
      title={`Choose Default Processor for ${currency}`}
    >
      {isLoading ? (
        <Spinner size={8} className="text-cyan-600" />
      ) : (
        <form onSubmit={handleSubmit} className="px-0 md:px-5">
          <div className="mt-3">
            <Select
              options={transactionTypeOptions}
              label="Transaction Type"
              onChange={(e: any) => setTransactionType(e?.target?.value)}
            />
          </div>
          <div className="mt-5">
            <Select
              options={processorOptions}
              label="Processor"
              onChange={(e: any) => setProcessor(e?.target?.value)}
            />
          </div>

          <div className="flex justify-end space-x-4 mt-8 mb-3">
            <Button
              onClick={onClose}
              variant="outline"
              colorScheme="cyan"
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="solid"
              colorScheme="cyan"
              disabled={isLoading}
            >
              Submit
            </Button>
          </div>
        </form>
      )}
    </CustomModal>
  );
};

export default UpdateProcessorModal;
