import React from "react";
import * as formatDate from "../../utils/date";
import { AfriexAdminUser, AfriexTelemetry } from "../../types";

const LogsTable = ({ items }: { items: AfriexTelemetry[] }) => {
  return (
    <table
      className={`min-w-full divide-y divide-gray-200 border shadow-sm rounded-md`}
    >
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="pl-6 py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Initiator
          </th>

          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Initiator Role
          </th>

          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Description
          </th>

          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Date Initiated
          </th>
        </tr>
      </thead>

      <tbody className="bg-white divide-y divide-gray-200">
        {items.map((item, index) => {
          return <LogsTRow item={item} key={item.id} index={index} />;
        })}
      </tbody>
    </table>
  );
};

const LogsTRow = ({
  item,
  index,
}: {
  item: AfriexTelemetry;
  index: number;
}) => {
  const textClassName = "text-sm text-gray-800";
  const zebraRow = (index + 1) % 2 === 0 ? "" : "bg-gray-50";

  return (
    <React.Fragment>
      <tr
        className={`hover:bg-gray-100 transition-background transition-colors transition-opacity ease-in-out duration-500 ${zebraRow}`}
      >
        <td
          className={`pl-6 py-4 whitespace-nowrap text-left ${textClassName} text-gray-800 font-medium`}
        >
          {(item?.meta?.admin as AfriexAdminUser)?.name}
        </td>

        <td
          className={`py-4 whitespace-nowrap text-left ${textClassName} text-gray-800 font-medium`}
        >
          {(item?.meta?.admin as AfriexAdminUser)?.role}
        </td>

        <td
          className={`py-4 whitespace-nowrap text-left ${textClassName} text-gray-800 font-medium`}
        >
          {item?.description}
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          {formatDate.DDMonYYYY(item.createdAt?.toString() || "", true)}
        </td>
      </tr>
    </React.Fragment>
  );
};

export default LogsTable;
