import AfriexLogsComponent from "../../../components/Logs/AfriexLogsComponent";
import { AfriexTelemetryObject, AfriexTelemetrySubject } from "../../../types";

const TradeLogs = () => {
  return (
    <AfriexLogsComponent
      tags={[AfriexTelemetrySubject.Admin, AfriexTelemetryObject.OTCVolume]}
      title="Trade Logs"
    />
  );
};

export default TradeLogs;
