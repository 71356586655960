import CustomModal from ".";
import { capitalizeWords } from "../../utils/dashboard";
import { FeeModalProps } from "../DeactivateAccount/types";

const FeeInfoModal = ({ isOpen, onClose, data }: FeeModalProps) => {
  const displayKeys = [
    "baseValue",
    "percentValue",
    "currencyPair",
    "isActive",
    "limit",
    "limitType",
    "paymentMethod",
    "transactionType",
    "type",
  ];

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      width="w-96"
      closeOnOutsideClick
      title="Fee Information"
    >
      <div className="flex flex-col gap-3 mb-5 px-2">
        {displayKeys.map((key) => {
          const value = data[key];
          if (!value) return;
          return (
            <div key={key} className="flex gap-3 items-center">
              <span className="text-sm font-medium w-1/2">{key}:</span>
              <span className="text-sm font-semibold uppercase">
                {capitalizeWords(String(value)?.replace(/_/g, " "))}
              </span>
            </div>
          );
        })}
      </div>
    </CustomModal>
  );
};

export default FeeInfoModal;
