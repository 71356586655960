import { DownloadIcon } from "@heroicons/react/outline";
import { Button } from "..";

const GenerateMultiPageButton = ({
  fileName,
  label,
}: {
  fileName: string;
  label: string;
}) => {
  const generatePDF = async () => {
    const html2pdf = await require("html2pdf.js");
    const contentElements = Array.from(
      document.getElementsByClassName("statement-content")
    ) as HTMLElement[];
    const firstContent = contentElements[0];

    if (!contentElements || contentElements.length === 0) {
      console.error("No elements with class '.statement-content' found.");
      return;
    }

    const curTime = new Date().getTime();
    const name = `${curTime}_${fileName ?? ""}_afriex.pdf`;

    // Combine all statement-content elements into one container
    const container = document.createElement("div");
    container.classList.add("statement-oga");
    contentElements.forEach((element) => {
      const clone = element.cloneNode(true) as HTMLElement;
      clone.classList.remove("w-680", "border-receipt-line", "border");
      container.appendChild(clone);
    });

    const pdfOptions = {
      margin: [0, 10, 0, 10],
      filename: name,
      image: { type: "png" },
      html2canvas: { scale: 1.3 },
      jsPDF: {
        unit: "px",
        format: [firstContent?.offsetWidth, firstContent?.offsetHeight + 5],
        hotfixes: ["px_scaling"],
        compress: true,
      },
      pagebreak: {
        mode: "avoid-all", // Ensure each .statement-content is treated as a separate page
      },
    };

    await html2pdf().from(container).set(pdfOptions).save();
  };

  return (
    <Button
      onClick={generatePDF}
      colorScheme="cyan"
      variant="outline"
      leftIcon={<DownloadIcon className="h-5 w-5" />}
      className="bg-indigo-200"
    >
      {label}
    </Button>
  );
};

export default GenerateMultiPageButton;
