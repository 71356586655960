import { Metrics } from "../types";

export interface SPPTableProps {
  metrics: Metrics;
}
export const ratesHeaderHue = "bg-blue-50 p-8 shadow-sm sticky";
const SuccessPerProcessorTable = ({ metrics }: SPPTableProps) => {
  return (
    <table
      className={`min-w-full divide-y divide-gray-200 border shadow-sm rounded-md`}
    >
      <thead className={ratesHeaderHue}>
        <tr>
          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Processor
          </th>

          <th
            scope="col"
            className="py-4 px-2 text-xs text-left font-semibold text-gray-700 uppercase tracking-wider"
          >
            Success Rate (%)
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {Object.keys(metrics.processorSuccessRates)
          .sort(
            (a, b) =>
              metrics.processorSuccessRates[a] -
              metrics.processorSuccessRates[b]
          )
          .map((processor, index) => (
            <SPPRow
              key={processor}
              index={index}
              metrics={metrics}
              processor={processor}
            />
          ))}
      </tbody>
    </table>
  );
};

const SPPRow = ({
  metrics,
  processor,
  index,
}: {
  metrics: Metrics;
  processor: string;
  index: number;
}) => {
  const textClassName = "text-sm text-gray-800";
  const zebraRow = index % 2 === 0 ? "" : "bg-gray-50";
  return (
    <tr
      className={`transition-background transition-colors transition-opacity ease-in-out duration-500 hover:bg-gray-100 ${zebraRow}`}
    >
      <td
        className={`py-4 px-2 whitespace-nowrap text-left ${textClassName} font-medium`}
      >
        {processor}
      </td>
      <td
        className={`py-4 px-2 whitespace-nowrap text-left font-medium ${textClassName}`}
      >
        {metrics.processorSuccessRates[processor].toFixed(2)}
      </td>
    </tr>
  );
};

export default SuccessPerProcessorTable;
