import { Metrics } from "../types";
import SuccessPerProcessorTable from "./PerProcessorTable";

const Processor = ({ metrics }: { metrics: Metrics }) => {
  return (
    <section className="pt-5 md:pt-5">
      <h1 className="text-lg font-semibold mb-2">
        Success Rate % per Processor
      </h1>

      <SuccessPerProcessorTable metrics={metrics} />
    </section>
  );
};

export default Processor;
