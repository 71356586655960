import RawMetricsTable from "./RawMetricsTable";
import { MetricsData } from "./types";

const RawMetrics = ({ data }: { data: MetricsData[] }) => {
  return (
    <div className="container mx-auto p-4 pt-0">
      <h1 className="text-lg font-bold my-4">Raw Metrics</h1>
      <RawMetricsTable data={data} />
    </div>
  );
};

export default RawMetrics;
