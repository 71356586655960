import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { useState } from "react";

interface AccordionProps {
  title: string;
  className?: string;
  defaultOpen?: boolean;
  headerStyle?: string;
  headerContent?: string;
}
const Accordion: React.FC<React.PropsWithChildren<AccordionProps>> = ({
  title,
  headerStyle,
  headerContent,
  className,
  defaultOpen,
  children,
}) => {
  const [isActive, setIsActive] = useState<boolean>(defaultOpen ?? false);

  return (
    <section className={`flex flex-col ${className}`}>
      <div
        className={`flex justify-between items-center py-1 px-3 cursor-pointer hover:text-cyan-600 ${headerStyle || "border-2 rounded-md border-gray-300"}`}
        onClick={() => setIsActive(!isActive)}
      >
        <p
          className={`font-semibold text-sm ${headerContent || "text-gray-700"}`}
        >
          {title}
        </p>
        {isActive ? (
          <ChevronUpIcon
            className={`w-5 h-5 ${headerContent || "text-gray-700"}`}
          />
        ) : (
          <ChevronDownIcon
            className={`w-5 h-5 ${headerContent || "text-gray-700"}`}
          />
        )}
      </div>
      <div className="py-1">{isActive && children}</div>
    </section>
  );
};

export default Accordion;
