import { capitalizeWords } from "../../utils/dashboard";

import "./receipt.css";
import GenerateOnePageReceiptButton from "./GenerateOnePageButton";
import ReceiptFooter from "./Footer";
import ReceiptHeader from "./Header";
import {
  AfriexActions,
  AfriexPermissions,
  TransactionTypes,
} from "../../types";
import { PermissionsProvider } from "../common/PermissionsProvider";

const Receipt = ({
  type,
  transactionType,
  children,
}: {
  type: "transaction" | "kyc";
  transactionType?: TransactionTypes;
  children: React.ReactNode;
}) => {
  const getReceiptTitle = () => {
    switch (type) {
      case "transaction":
        return "Remittance Transaction Receipt";
      default:
        return `${capitalizeWords(type)} Receipt`;
    }
  };

  const getFileName = () => {
    if (type === "transaction") {
      switch (transactionType) {
        case TransactionTypes.WITHDRAW:
          return "withdraw";
        case TransactionTypes.DEPOSIT:
          return "deposit";
        case TransactionTypes.TRANSFER:
          return "transfer";
        default:
          return "transaction";
      }
    } else if (type === "kyc") {
      return "kyc";
    } else {
      return "";
    }
  };

  const permission =
    type === "transaction"
      ? AfriexPermissions.TRANSACTIONS_PAGE
      : AfriexPermissions.KYC_PAGE;
  const action =
    type === "transaction"
      ? AfriexActions.CLICK_DOWNLOAD_TRANSACTION_RECEIPT
      : AfriexActions.CLICK_DOWNLOAD_KYC_RECEIPT;

  return (
    <div className="flex flex-col gap-5 mt-5">
      <div className="text-right">
        {/* Generate Receipt Button */}
        <PermissionsProvider permission={permission} action={action}>
          <GenerateOnePageReceiptButton
            fileName={getFileName()}
            label={`Download${type === "kyc" ? " KYC" : " Receipt"}`}
          />
        </PermissionsProvider>
      </div>
      <div
        id="receipt-parent"
        className={`flex w-450 flex-col justify-center border border-receipt-line rounded-md`}
      >
        <ReceiptHeader />

        <div className="body mx-7">
          <p className="header font-bold text-sm text-cyan-600">
            {getReceiptTitle()}
          </p>

          {children}
        </div>

        <ReceiptFooter />
      </div>
    </div>
  );
};

Receipt.displayName = "Receipt";
export default Receipt;
