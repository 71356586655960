import { DashCardConfig } from "../config/menuConfig";
import { userService } from "../services/userService";
import {
  AfriexTransaction,
  TransactionChannels,
  TransactionTypes,
} from "../types";

export const formatValues = (
  value: number,
  type: DashCardConfig["valueType"],
  fraction?: number, // Control decimal places
  showCurrencySymbol = false // Control currency symbol display
) => {
  let formattedValue: string;
  switch (type) {
    case "money":
      formattedValue = new Intl.NumberFormat("en-US", {
        ...(showCurrencySymbol && {
          currencyDisplay: "symbol",
          style: "currency",
          currency: "USD",
        }),
        maximumFractionDigits: fraction !== undefined ? fraction : 2,
        minimumFractionDigits: fraction !== undefined ? fraction : 2,
      }).format(value);
      break;

    case "rate":
      formattedValue = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: fraction !== undefined ? fraction : 2,
        minimumFractionDigits: Number.isInteger(value)
          ? 0
          : fraction !== undefined
            ? fraction
            : 2,
      }).format(value);
      break;

    default:
      formattedValue = "0"; // Fallback for unexpected types
  }

  // Trim trailing zeros if the value is a decimal
  if (formattedValue.includes(".")) {
    formattedValue = formattedValue.replace(/\.?0+$/, "");
  }

  return formattedValue;
};

export function capitalizeWords(str: string) {
  return str
    ?.split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export const formatAdminTransaction = async (data: AfriexTransaction) => {
  let { type } = data;
  let adminUser: { name: string; email: string } | null = null;

  if (data.initiatedBy && data.channel === TransactionChannels.ADMIN) {
    type = `ADMIN_${data.type}` as TransactionTypes;
    try {
      const getAdminUser = await userService.getUser(data.initiatedBy);
      adminUser = {
        name: getAdminUser.name.fullName,
        email: getAdminUser.currentEmail,
      };
    } catch (error) {
      console.error("Error fetching admin user:", error);
    }
  }

  return {
    type,
    adminUser: adminUser || null,
  };
};

export const formatTxChannel = (tx: AfriexTransaction) => {
  const hasE2e = tx.processorResponseMeta?.transactionType === "E2E";
  return `${capitalizeWords(tx.channel?.replace(/_/g, " "))} ${hasE2e ? "(E2E)" : ""}`;
};

/**
 * Compares two display fields and returns an array containing either just the preferred field
 * (if values match) or both fields (if values differ).
 *
 * @param preferredField - The preferred field object with key-value pair
 * @param secondaryField - The secondary field object with key-value pair
 * @returns Array of field objects to display
 */
export const compareAndFilterFields = (
  preferredField: { [key: string]: any },
  secondaryField: { [key: string]: any }
): Array<{ [key: string]: any }> => {
  // Extract keys and values
  const preferredKey = Object.keys(preferredField)[0];
  const secondaryKey = Object.keys(secondaryField)[0];
  const preferredValue =
    typeof preferredField[preferredKey] === "string"
      ? capitalizeWords(preferredField[preferredKey]?.replace(/_/g, " "))
      : preferredField[preferredKey];
  const secondaryValue =
    typeof secondaryField[secondaryKey] === "string"
      ? capitalizeWords(secondaryField[secondaryKey]?.replace(/_/g, " "))
      : secondaryField[secondaryKey];

  // If values are exactly the same, return only the preferred field
  if (preferredValue === secondaryValue) {
    return [preferredField];
  }

  // If values are different, return both fields
  return [preferredField, secondaryField];
};
