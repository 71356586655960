import { Header, Pagination } from "../../../components";
import { PaginationProps } from "../../../components/common/Pagination";
import TableSkeleton from "../../../components/Table/TableSkeleton";
import ReferralFilters from "./ReferralFilters";
import ReferralTable from "./ReferralTable";
import useReferralList from "./useReferralList";

const ReferralList = () => {
  const {
    isLoading,
    isSearch,
    isPageReset,
    fetchReferralList,
    pageInfo,
    referralList,
    filterCount,
    currentFilters,
    filtersToApply,
    handleApplyFilters,
    clearFilters,
    handleAutoComplete,
    selectAppliedFilters,
    selectCurrentFilters,
    handlePayment,
    handleBlockPayout,
  } = useReferralList();

  const totalFigure = pageInfo.total
    ? ` (${pageInfo.total.toLocaleString()})`
    : "";

  return (
    <main className="mt-10 mb-5 mx-4 md:mx-10">
      <Header title={"Referral List" + totalFigure} />

      <ReferralFilters
        filterCount={filterCount}
        currentFilters={currentFilters}
        filtersToApply={filtersToApply}
        clearFilters={clearFilters}
        selectCurrentFilters={selectCurrentFilters}
        selectAppliedFilters={selectAppliedFilters}
        handleApplyFilters={handleApplyFilters}
        handleAutoComplete={handleAutoComplete}
      />

      <section className="pt-5 md:pt-5">
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <ReferralTable
            items={referralList}
            handlePayment={handlePayment}
            handleBlockPayout={handleBlockPayout}
          />
        )}
      </section>

      {referralList && referralList.length ? (
        <Pagination
          onPageChange={fetchReferralList as PaginationProps["onPageChange"]}
          total={pageInfo.total}
          isSearch={isSearch}
          isPageReset={isPageReset}
        />
      ) : null}
    </main>
  );
};

export default ReferralList;
